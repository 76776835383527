import React, { useState } from "react";
import {
  MdOutlineNotifications,
  MdOutlinePowerSettingsNew,
} from "react-icons/md";

import { VscKey } from "react-icons/vsc";
import { RxAvatar } from "react-icons/rx";
import { useNavigate, useParams } from "react-router-dom";

import Customers from "./Customers";
import Sales from "./Sales";
import Menu from "./Menu";
import Complaints from "./Complaints";
import DashboardHome from "./DashboardHome";
import Orders from "./orders/Orders";
import SidebarCustom from "../component/Sidebar";
import CategoryDetails from "../pages/CategoryDetails";

import { useSelector } from "react-redux";
import Employee from "./Employee/Employee";

import EmployeeDetail from "./Employee/components/EmployeeDetail";
import Schedule from "./schedule/Schedule";
import Attendance from "./Attendance/Attendance";
import AttendanceOverview from "./Attendance/AttendanceOverview";

const Main = () => {
  const navigate = useNavigate();
  const params = useParams();
  let { subpage } = useParams();

  const isAdminAuth = useSelector((state) => state.auth.isAdminAuth);
  const isEmployeeAuth = useSelector((state) => state.auth.isEmployeeAuth);

  console.log("Subpage Main==>", subpage);

  const pageName = subpage?.replace("-", " ");

  return (
    <div className="nunito-sans flex bg-[#EDEDE9] p-2">
      <SidebarCustom subpage={subpage} className="flex-shrink-0 bg-gray-800" />
      <div className="min-h-screen w-screen md:px-10 px-4">
        <button
          onClick={() => window.history.back()}
          className="flex items-center justify-center rounded-full bg-white p-2 text-gray-500 hover:bg-gray-100"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
            />
          </svg>
        </button>
        <header className="flex h-auto w-full items-center justify-between ">
          {/* <h1 className="text-2xl font-semibold capitalize">{pageName}</h1> */}
          {/* <div className="flex items-center justify-between rounded-2xl bg-white w-full p-6 text-stone-50">
            <h1 className="text-lg   capitalize">{pageName}</h1>

            <div className="flex items-center justify-between gap-4">
              <MdOutlineNotifications size={22} className="cursor-pointer" />
              <p className="font-semibold">Username</p>

              <RxAvatar size={30} className="cursor-pointer" />
            </div>
          </div> */}
        </header>

        {isAdminAuth && (
          <div>
            {subpage === "main" && <DashboardHome />}
            {subpage === "orders" && <Orders />}
            {subpage === "customers" && <Customers />}
            {subpage === "menu" && <Menu />}
            {subpage === "employees" && <Employee />}
            {subpage === "attendance" && <Attendance />}
            {subpage === "attendance-overview" && <AttendanceOverview />}
            {subpage === "schedule" && <Schedule />}
            {subpage === "complaints" && <Complaints />}
            {subpage === "sales-report" && <Sales />}
            {subpage === "category-details" && <CategoryDetails />}
            {subpage === "employee-details" && <EmployeeDetail />}
            
          </div>
        )}

        {isEmployeeAuth && (
          <div>
            {subpage === "attendance" && <Attendance />}
            {subpage === "schedule" && <Schedule />}
          </div>
        )}
      </div>
    </div>
  );
};

export default Main;
