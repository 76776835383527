import React, { useState } from "react";
import EmployeeTable from "./EmployeeTable";
import EmployeeForm from "./EmployeeForm";
import { GlobalModalV1 } from "../../component/GloobalModal";

const Employee = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);
  return (
    <div className="my-10">
      <div className="flex flex-1 flex-col">
        <button
          onClick={onOpen}
          className="self-start rounded-lg border-2 duration-200 transition-all border-primary bg-transparent px-4 py-2 hover:bg-primary hover:text-white"
        >
          Add Employee
        </button>

        <GlobalModalV1 onClose={onClose} isOpen={isOpen} width={"800px"}>
          <EmployeeForm onClose={onClose} isOpen={isOpen} />
        </GlobalModalV1>
      </div>
      <EmployeeTable />
    </div>
  );
};

export default Employee;
