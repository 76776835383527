import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "@ag-grid-community/react";
import { useDeleteSchedule } from "../../../features/schedule/schedule-service";
import { GlobalModalV1 } from "../../../component/GloobalModal.jsx";
import ScheduleForm from "./ScheduleForm";
import { formatedDate } from "../../../utils/data.js";

const EmployeeScheduleTable = ({ employeeData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);

  const onClose = () => setIsOpen(false);



  const createDeleteMutation = useDeleteSchedule();

  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const rowsPerPage = 10;

  //   const filteredData = {
  //     data:employeeData.createdAt
  //   }

  useEffect(() => {
    console.log("employeeData inside useEffect:", employeeData);

    if (employeeData?.employee?.schedules) {
      setTableData(employeeData.employee.schedules);
    }
  }, [employeeData]);

  const sortedTableData = tableData.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  console.log("Sorted Table Data===>", sortedTableData);

  const totalPages = Math.ceil(tableData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;

  // Safeguard to ensure tableData is always an array
  const currentData = Array.isArray(tableData)
    ? tableData
        .slice(startIndex, startIndex + rowsPerPage)
        .map((item, index) => ({
          ...item,
          id: index + 1,
        }))
    : [];

  console.log("Current Data===>", currentData);

  const handleUpdate = () => {
    const filter = currentData?.filter((item) => item?._id === selectedIdEdit);
    setSelectedData(filter);
    setSelectedIdEdit(null);
  };

  useEffect(() => {
    if (selectedId) {
      handleDelete();
      console.log(selectedId, "selectedId");
    }
    if (selectedIdEdit) {
      handleUpdate();
      console.log(selectedIdEdit, "selectedIdEdit");
    }
  }, [selectedId, selectedIdEdit]);

  const handleDelete = () => {
    createDeleteMutation.mutate(
      { apiUrl: `/api/schedule/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);
          toast.success("Schedule Deleted Successfully");
        },
        onError: (error) => {
          console.error("Error deleting Schedule", error);
          toast.error("Error deleting Schedule");
        },
      }
    );
  };

  const columns = [
    {
      headerName: "Date",
      valueGetter: (params) => formatedDate(params.data.date),
    },
    {
      headerName: "Shift 1 (Morning)",
      cellRenderer: (params) => {
        return params.data.shift === "morning"
          ? `${params.data.startTime || ""} - ${params.data.endTime || ""}`
          : "-";
      },
    },
    {
      headerName: "Shift 2 (Afternoon)",
      cellRenderer: (params) => {
        return params.data.shift === "night"
          ? `${params.data.startTime || ""} - ${params.data.endTime || ""}`
          : "-";
      },
    },
    {
      headerName: "Notes",
      valueGetter: (params) => params.data.notes,
    },
  ];

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,
    floatingFilter: true,
    flex: 1,
  };

  return (
    <div>
      <div className="ag-theme-quartz mt-6" style={{ height: 500 }}>
        <AgGridReact
          modules={[ClientSideRowModelModule]}
          rowData={currentData}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[10, 25, 50]}
        />
      </div>
      <GlobalModalV1 isOpen={isOpen} onClose={onClose} width={"800px"}>
        <ScheduleForm
          onClose={onClose}
          isUpdateModal={true}
          selectedData={selectedData}
        />
      </GlobalModalV1>
    </div>
  );
};

export default EmployeeScheduleTable;
