import React, { useEffect, useState } from "react";
import { GlobalModalV1 } from "../../component/GloobalModal";
import ScheduleForm from "./components/ScheduleForm";
import ScheduleTable from "./components/ScheduleTable";
import { useSelector } from "react-redux";
import {
  useFetchEmployeeById,
  useSchedulesByEmployeeId,
} from "../../features/Employee/employee-service";
import EmployeeScheduleTable from "./components/EmployeeScheduleTable";
import CustomTabs from "../../component/CustomTabs";
import { useAllSchedules } from "../../features/schedule/schedule-service";

const Schedule = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { isEmployeeAuth } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);

  // const {
  //   data: employeeSchedules,
  //   isLoading,
  //   isError,
  // } = useSchedulesByEmployeeId(
  //   user.EmployeeRef ? `/api/employee/schedules/${user.EmployeeRef}` : null
  // );

  const {
    data: employee,
    isLoading,
    isError,
  } = useFetchEmployeeById(
    user.EmployeeRef ? `/api/employee/${user.EmployeeRef}` : null
  );

  const {
    data: scheduleResponse,
    isLoadingSchedules,
    isErrorSchedules,
  } = useAllSchedules("/api/schedules");

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (scheduleResponse) {
      if (
        scheduleResponse.schedules &&
        Array.isArray(scheduleResponse.schedules)
      ) {
        setTableData(scheduleResponse.schedules);
      }
    }
  }, [scheduleResponse]);

  if (isLoading && isLoadingSchedules) return <div>Loading Test...</div>;

  //

  // console.log("Table Data===>", tableData);

  console.log("Schedule Response===>", scheduleResponse);

  // Safeguard to ensure tableData is always an array
  const currentData = Array.isArray(tableData)
    ? tableData.map((item, index) => ({
        ...item,
        id: index + 1,
      }))
    : [];

  //Pending Schedules Data
  const pendingData = currentData.filter((data) => data.status === "pending");
  const approvedData = currentData.filter((data) => data.status === "approved");
  const rejectedData = currentData.filter((data) => data.status === "rejected");

  console.log("Current Data===>", pendingData);

  console.log("Approved Data===>", approvedData);
  console.log("Rejected Data===>", rejectedData);


  const tabData = [
    {
      label: "Pending Schedules",
      content: <ScheduleTable currentData={pendingData} />,
    },
    {
      label: "Approved Schedules",
      content: <ScheduleTable currentData={approvedData} />,
    },
    {
      label: "Rejected Schedules",
      content: <ScheduleTable currentData={rejectedData} />,
    },
  ];

  return (
    <div className="my-10">
      <h1 className="text-3xl font-bold border-b-4 border-b-primary">
        Schedule
      </h1>

      {isEmployeeAuth ? (
        <div className="flex flex-col mt-4">
          {/* {employee.employee.schedules[0].date ===
            new Date().toDateString() && (
            <button
              onClick={onOpen}
              className="self-start rounded-lg border-2 transition-all duration-200 border-primary bg-transparent px-4 py-2 hover:bg-primary hover:text-white"
              aria-label="Create Schedule"
            >
              Create Schedule
            </button>
          )} */}

          {employee?.employee?.schedules &&
            !employee.employee.schedules.some(
              (schedule) =>
                new Date(schedule.date).toDateString() ===
                new Date().toDateString()
            ) && (
              <button
                onClick={onOpen}
                className="self-start rounded-lg border-2 transition-all duration-200 border-primary bg-transparent px-4 py-2 hover:bg-primary hover:text-white"
                aria-label="Create Schedule"
              >
                Create Schedule
              </button>
            )}

          <GlobalModalV1 onClose={onClose} isOpen={isOpen} width={"800px"}>
            <ScheduleForm onClose={onClose} isOpen={isOpen} />
          </GlobalModalV1>
          <EmployeeScheduleTable employeeData={employee} />
        </div>
      ) : (
        <div className="mt-10">
          <CustomTabs tabData={tabData} />
        </div>
      )}
    </div>
  );
};

export default Schedule;
