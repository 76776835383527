import axios from "axios";
import React, { useState } from "react";
import { FaSquareCaretUp } from "react-icons/fa6";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "../features/cart/action";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { dotSpinner } from "ldrs";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

const Checkout = () => {
  // const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [formData, setFormData] = useState({
  //   firstName: "",
  //   lastName: "",
  //   email: "",
  //   phoneNumber: "1",
  //   address: "",
  //   note: "",
  //   acceptedPaymentMethods: "",
  // });

  // const schema = yup.object({
  //   firstName: yup.string().required("First Name is required"),
  //   lastName: yup.string().required("Last Name is required"),
  //   email: yup
  //     .string()
  //     .email("Invalid email format")
  //     .required("Email is required"),
  //   phoneNumber: yup
  //     .string()
  //     .matches(/^[2-9]\d{9}$/, "Please enter a valid U.S. phone number (e.g., +12025550142).")
  //     .required("Phone number is required"),
  //   address: yup.string().required("Address is required"),
  //   note: yup.string().optional(),
  // });
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors, isSubmitting },
  // } = useForm({
  //   resolver: yupResolver(schema),
  // });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  dotSpinner.register();

  const apiUrl = process.env.REACT_APP_BASE_URL || "http://localhost:5000";
  const cart = useSelector((state) => state.cart.cart);
  console.log("Cart Data===>", cart);

  const subTotal = cart
    .reduce((acc, item) => {
      const price =
        item?.itemData?.variations[0]?.itemVariationData?.priceMoney?.amount;
      return acc + (price ? parseInt(price, 10) : 0);
    }, 0)
    .toFixed(2);

  console.log("Subtotal===>", subTotal);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prev) => ({ ...prev, [name]: value }));
  // };

  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);
  //   console.log("formData", values);

  //   let anotherApiResponse = null;
  //   const itemData = cart.map((item) => ({
  //     itemId: item.itemData.variations[0].id,
  //     quantity: item.quantity,
  //     specialInstructions: "Extra toasted",
  //   }));
  //   console.log("Catalog object id===>", itemData);
  //   try {
  //     const customerData = {
  //       firstName: values.firstName,
  //       lastName: values.lastName,
  //       email: values.email,
  //       phoneNumber: "1" + values.phoneNumber,
  //       address: values.address,
  //     };

  //     const createCustomerResponse = await axios.post(
  //       `${apiUrl}/api/customer`,
  //       customerData
  //     );
  //     console.log("Create Customer Response:", createCustomerResponse.data);

  //     if (createCustomerResponse.data.success) {
  //       const customerId = createCustomerResponse.data.customer.id;
  //       console.log("Customer ID:", customerId);

  //       const additionalData = {
  //         customerId,
  //         locationId: "2S2Z8AGQ60RYV",
  //         items: itemData,
  //         note: values.note,
  //         tipSettings: {
  //           allowCustomTip: true,
  //           suggestedTipPercentages: [15, 18, 20, 25],
  //         },
  //         fulfillment: {
  //           type: "PICKUP",
  //           pickupDetails: {
  //             pickupAt: "2024-03-20T15:00:00Z",
  //             note: "Please pickup at counter",
  //           },
  //         },
  //         shippingOptions: [
  //           {
  //             name: "Standard Delivery",
  //             amount: 5.99,
  //             duration: "2-3 business days",
  //           },
  //           {
  //             name: "Express Delivery",
  //             amount: 12.99,
  //             duration: "1 business day",
  //           },
  //         ],
  //         prePopulateBuyerEmail: values.email,
  //         prePopulateShippingAddress: {
  //           firstName: values.firstName,
  //           lastName: values.lastName,
  //           addressLine1: values.address,
  //           city: "New York",
  //           state: "NY",
  //           postalCode: "10001",
  //           country: "US",
  //         },
  //         acceptedPaymentMethods: {
  //           applePay: true,
  //           googlePay: true,
  //           cashAppPay: true,
  //           card: true,
  //         },
  //       };

  //       anotherApiResponse = await axios.post(
  //         `${apiUrl}/api/advanced-checkout`,
  //         additionalData
  //       );
  //       console.log("Another API Response:", anotherApiResponse.data);
  //     } else {
  //       toast.error(
  //         `Error in creating customer: ${createCustomerResponse.data.error}`,
  //         {
  //           duration: 3000,
  //           position: "top-center",
  //         }
  //       );
  //       console.error(
  //         "Error in creating customer:",
  //         createCustomerResponse.data.error
  //       );
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     toast.error(`Something went wrong: ${error.message}`, {
  //       duration: 3000,
  //       position: "top-center",
  //     });
  //     console.error("API Error:", error.response?.data || error.message);
  //   } finally {
  //     if (anotherApiResponse?.data?.checkoutUrl) {
  //       setIsLoading(false);
  //       window.location.href = anotherApiResponse.data.checkoutUrl;
  //       dispatch(clearCart());
  //     } else {
  //       toast.error(`Unable to perocess order!`, {
  //         duration: 3000,
  //         position: "top-center",
  //       });
  //       console.error("Checkout URL not available.");
  //     }
  //   }
  // };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let anotherApiResponse = null;
    const itemData = cart.map((item) => ({
      itemId: item.itemData.variations[0].id,
      quantity: item.quantity,
      specialInstructions: "Extra toasted",
    }));
    console.log("Catalog object id===>", itemData);
    try {
      const additionalData = {
        locationId: "2S2Z8AGQ60RYV",
        items: itemData,
        tipSettings: {
          allowCustomTip: true,
          suggestedTipPercentages: [15, 18, 20, 25],
        },
        fulfillment: {
          type: "PICKUP",
          pickupDetails: {
            pickupAt: "2024-03-20T15:00:00Z",
            note: "Please pickup at counter",
          },
        },
        shippingOptions: [
          {
            name: "Standard Delivery",
            amount: 5.99,
            duration: "2-3 business days",
          },
          {
            name: "Express Delivery",
            amount: 12.99,
            duration: "1 business day",
          },
        ],
        prePopulateShippingAddress: {
          city: "New York",
          state: "NY",
          postalCode: "10001",
          country: "US",
        },
        acceptedPaymentMethods: {
          applePay: true,
          googlePay: true,
          cashAppPay: true,
          card: true,
        },
      };

      anotherApiResponse = await axios.post(
        `${apiUrl}/api/advanced-checkout`,
        additionalData
      );
    } catch (error) {
      toast.error(`Something went wrong: ${error.message}`, {
        duration: 3000,
        position: "top-center",
      });
      console.error("API Error:", error.response?.data || error.message);
    } finally {
      if (anotherApiResponse?.data?.checkoutUrl) {
        window.location.href = anotherApiResponse.data.checkoutUrl;
        dispatch(clearCart());
      } else {
        toast.error(`Unable to process order!`, {
          duration: 3000,
          position: "top-center",
        });
        console.error("Checkout URL not available.");
      }
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h2 className="text-4xl text-center font-bold text-[#00000040] mb-8">
        Checkout
      </h2>

      <div className="max-w-[700px] mx-auto">
        {/* Main Content - Takes up 2 columns */}
        {/* <div className="lg:col-span-2 space-y-6">
          <div className="bg-white rounded-lg shadow-sm">
            <div className="bg-[#666666] text-white p-4 rounded-t-lg">
              Contact Information
            </div>
            <div className="p-6">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <div className="space-y-1">
                    <input
                      className="w-full px-4 py-3 rounded-lg border border-zinc-300 focus:ring-2 focus:ring-[#eab43e] focus:border-transparent transition"
                      {...register("firstName")}
                      placeholder="First Name"
                    />
                    {errors.firstName && (
                      <p className="text-red-500 text-sm">
                        {errors.firstName.message}
                      </p>
                    )}
                  </div>
                  <div className="space-y-1">
                    <input
                      className="w-full px-4 py-3 rounded-lg border border-zinc-300 focus:ring-2 focus:ring-[#eab43e] focus:border-transparent transition"
                      {...register("lastName")}
                      placeholder="Last Name"
                    />
                    {errors.lastName && (
                      <p className="text-red-500 text-sm">
                        {errors.lastName.message}
                      </p>
                    )}
                  </div>
                  <div className="space-y-1">
                    <input
                      className="w-full px-4 py-3 rounded-lg border border-zinc-300 focus:ring-2 focus:ring-[#eab43e] focus:border-transparent transition"
                      {...register("email")}
                      placeholder="Email"
                    />
                    {errors.email && (
                      <p className="text-red-500 text-sm">
                        {errors.email.message}
                      </p>
                    )}
                  </div>
                  <div className="space-y-1">
                    <div className="relative">
                      <input
                        className="w-full pl-10 pr-4 py-3 rounded-lg border border-zinc-300 focus:ring-2 focus:ring-[#eab43e] focus:border-transparent transition"
                        {...register("phoneNumber", {
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message: "Enter a valid 10-digit phone number",
                          },
                        })}
                        placeholder="Phone"
                      />
                      <span className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-500 pointer-events-none">
                        +1
                      </span>
                    </div>
                    {errors.phoneNumber && (
                      <p className="text-red-500 text-sm">
                        {errors.phoneNumber.message}
                      </p>
                    )}
                  </div>
                  <div className="space-y-1">
                    <textarea
                      className="w-full px-4 py-3 rounded-lg border border-zinc-300 focus:ring-2 focus:ring-[#eab43e] focus:border-transparent transition"
                      {...register("address")}
                      placeholder="Delivery Address"
                      rows="3"
                    />
                    {errors.address && (
                      <p className="text-red-500 text-sm">
                        {errors.address.message}
                      </p>
                    )}
                  </div>
                  <div className="space-y-1">
                    <textarea
                      className="w-full px-4 py-3 rounded-lg border border-zinc-300 focus:ring-2 focus:ring-[#eab43e] focus:border-transparent transition"
                      {...register("note")}
                      placeholder="Notes"
                      rows="3"
                    />
                  </div>
                </div>

                <button
                  className="w-full bg-[#eab43e] text-white py-4 rounded-lg font-medium hover:bg-[#d9a636] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  type="submit"
                  disabled={isLoading}
                >
                  {!isLoading ? (
                    "Place Order"
                  ) : (
                    <div className="flex items-center justify-center space-x-2">
                      <span>Placing</span>
                      <l-dot-spinner size="20" speed="0.8" color="white" />
                    </div>
                  )}
                </button>
              </form>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-sm">
            <div className="bg-[#666666] text-white p-4 rounded-t-lg">
              Stay in touch
            </div>
            <div className="p-6 space-y-4">
              <div className="flex items-center">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer" />
                  <div className="w-[50px] h-6 bg-[#ccc] peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-500 peer-checked:bg-blue-600 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-5 after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Sign Up
                  </span>
                </label>
              </div>
              <div>
                <p className=" mt-2 sm:text-[12px] text-[10px]">
                  By signing up, I agree to receive automated informational and
                  marketing texts, including Loyalty messages, coupons, and
                  discounts. Joining this program is not a condition of
                  purchase. To unsubscribe at any time, reply “END”, std rates
                  apply. The card you use for this or future transactions will
                  be automatically linked with your account to surface offers or
                  rewards.
                </p>
              </div>
            </div>
          </div>
        </div> */}

        {/* Cart Summary - Takes up 1 column */}
        <div className="lg:sticky lg:top-8 mt-8 lg:mt-0">
          <div className="bg-[#666666] rounded-lg shadow-lg p-6 text-white">
            <h3 className="text-xl font-semibold border-b border-white/20 pb-4 mb-6">
              Cart Summary
            </h3>

            <form onClick={onSubmit} className="space-y-6">
              <div className="space-y-4">
                <div>
                  <strong className="text-lg block mb-3">Items</strong>
                  <ul className="space-y-3">
                    {cart.map((item) => (
                      <li
                        key={item?.id}
                        className="flex justify-between items-center text-sm"
                      >
                        <div className="flex-1">
                          <span className="text-white/90">
                            {item?.itemData?.name}
                          </span>
                          <span className="text-white/70 ml-2">
                            x {item?.quantity}
                          </span>
                        </div>
                        <span className="text-white/90">
                          $
                          {(
                            item?.itemData?.variations[0]?.itemVariationData
                              ?.priceMoney?.amount * item?.quantity
                          ).toFixed(2)}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="space-y-3 pt-4 border-t border-white/20">
                  <div className="flex justify-between text-sm">
                    <span className="text-white/80">Subtotal</span>
                    <span>
                      $
                      {cart
                        .reduce(
                          (total, item) =>
                            total +
                            item.itemData?.variations[0]?.itemVariationData
                              ?.priceMoney?.amount *
                              item.quantity,
                          0
                        )
                        .toFixed(2)}
                    </span>
                  </div>

                  <div className="flex justify-between text-sm">
                    <span className="text-white/80">
                      Estimated taxes (GA, US)
                    </span>
                    <span>$5.00</span>
                  </div>

                  <div className="flex justify-between text-sm">
                    <span className="text-white/80">Delivery Charges</span>
                    <span>$0.00</span>
                  </div>
                </div>
              </div>

              <div className="pt-4 border-t border-white/20">
                <div className="flex justify-between items-center mb-4">
                  <span className="text-lg font-medium">Total</span>
                  <span className="text-xl font-semibold">
                    $
                    {(
                      parseFloat(
                        cart.reduce(
                          (total, item) =>
                            total +
                            item.itemData?.variations[0]?.itemVariationData
                              ?.priceMoney?.amount *
                              item.quantity,
                          0
                        )
                      ) +
                      5.0 +
                      0.0
                    ).toFixed(2)}
                  </span>
                </div>

                {cart.length > 0 && (
                  <button
                    disabled={isLoading}
                    className="w-full disabled:opacity-50 disabled:cursor-not-allowed bg-[#eab43e] text-white py-4 rounded-lg font-medium hover:bg-[#d9a636] transition-colors text-lg flex justify-center items-center gap-4"
                  >
                    Proceed to Payment
                    {isLoading && (
                      <l-dot-spinner size="20" speed="0.8" color="white" />
                    )}
                  </button>
                )}
              </div>
            </form>
            {cart.length === 0 && (
              <Link to="/menu">
                <p className="w-full text-center bg-[#eab43e] text-white py-4 rounded-lg font-medium hover:bg-[#d9a636] transition-colors text-lg">
                  Add Items
                </p>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
