import React, { useState } from "react";
import EmployeeAttendanceAccordion from "../../component/EmployeeAttendanceAccordion";
import OverviewCalendar from "../../component/OverviewCalendar";

const AttendanceOverview = () => {
  
  return (
    <div>
      <h1 className="text-[34px] border-b-4 border-primary p-2">
        Attendance Overview
      </h1>

      <div className="my-10">
        <OverviewCalendar />
        {/* <EmployeeAttendanceAccordion /> */}
      </div>
    </div>
  );
};

export default AttendanceOverview;
