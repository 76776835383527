import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { GET_ALL_SCHEDULES } from "./schedule-types"
import { create, deleteData, fetchAll, getById, update } from "../../Apis/Apis"


//Get all schedules
export const useAllSchedules = (apiUrl) => {
    return useQuery({
        queryKey: [GET_ALL_SCHEDULES],
        queryFn: () => fetchAll(apiUrl)
    })
};


//Create schedule
export const useCreateSchedule = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: create,
        onMutate: async (newSchedule) => {
            queryClient.invalidateQueries(GET_ALL_SCHEDULES);
        },
        onError: (error, newSchedule, useContext) => {
            console.log("Error in create schedule", error)
        },
        onSuccess: (data, newSchedule) => {
            queryClient.invalidateQueries(GET_ALL_SCHEDULES);
        },
        onSettled: () => {
            queryClient.invalidateQueries(GET_ALL_SCHEDULES);
        }
    })
}


//Update schedule
export const useUpdateSchedule = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: update,
        onMutate: async (updateSchedule) => {
            queryClient.invalidateQueries(GET_ALL_SCHEDULES)
        },
        onError: (error, context) => {
            queryClient.invalidateQueries(GET_ALL_SCHEDULES);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(GET_ALL_SCHEDULES)
        },
        onSettled: () => {
            queryClient.invalidateQueries(GET_ALL_SCHEDULES)
        }
    })
}


//Delete schedule
export const useDeleteSchedule = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: deleteData,
        onMutate: async (newSchedule) => {
            queryClient.invalidateQueries(GET_ALL_SCHEDULES)
        },
        onError: (error, context) => {
            console.log("Error in delete schedule", error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(GET_ALL_SCHEDULES)
        },
        onSettled: () => {
            queryClient.invalidateQueries(GET_ALL_SCHEDULES)
        }
    })
}


//Get schedule by id
export const useGetSchedule = (apiUrl) => {
    return useQuery({
        queryKey: [GET_ALL_SCHEDULES],
        queryFn: getById(apiUrl),
        enabled: !!apiUrl
    })
}

//Update schedule status
export const useUpdateScheduleStatus = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: update,
        onMutate: async (updateScheduleStatus) => {
            queryClient.invalidateQueries(GET_ALL_SCHEDULES)
        },
        onError: (error, context) => {
            queryClient.invalidateQueries(GET_ALL_SCHEDULES);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(GET_ALL_SCHEDULES)
        },
        onSettled: () => {
            queryClient.invalidateQueries(GET_ALL_SCHEDULES)
        }
    })
}