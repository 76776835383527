import React, { useEffect, useRef, useState } from "react";
import MenuNav from "../component/MenuNav";
import { bagels_products, categories } from "../utils";

import ProductCard from "../component/ProductCard";
import { useAllCategoriesWithItems } from "../features/category/category-service";
import { useAllProducts } from "../features/products/products-service";

const Menu = () => {
  const [navPosition, setNavPosition] = useState("top-[100px]");

  const sectionRefs = useRef([]);
  const navRef = useRef(null);
  const { data: category } = useAllCategoriesWithItems();
  // console.log("All categories===>", category);
  const { data: products } = useAllProducts();
  console.log("All products===>", products);
  console.log("Category===>", category);

  useEffect(() => {
    sectionRefs.current = sectionRefs.current.slice(
      0,
      category?.categories?.length || 0
    );
  }, [category]);

  return (
    <div className="w-full px-4 md:px-16 ">
      {/* <h1 className="text-4xl font-semibold text-[#bfbfbf] mt-16">Menu</h1> */}

      <MenuNav
        navPosition={navPosition}
        setNavPosition={setNavPosition}
        sectionRefs={sectionRefs}
        navRef={navRef}
        category={category}
      />

      {/* Sections */}
      {/* <div className="pt-24">
        {category?.categories?.map((category, index) => (
          <div
            key={index}
            ref={(el) => (sectionRefs.current[index] = el)}
            className="mt-20 "
          >
            <div>
              <h2 className="text-3xl font-bold">
                {category?.categoryData?.name}
              </h2>

              <div className="flex justify-center items-center gap-10 flex-wrap my-10 py-10">
                {products?.products.map((product, index) => (
                  <ProductCard product={product} key={index} />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div> */}

      <div className="pt-16">
        {category?.categories?.map((categoryItem, categoryIndex) => (
          <div
            key={categoryIndex}
            ref={(el) => (sectionRefs.current[categoryIndex] = el)}
            className="mt-10"
          >
            <div className="md:px-14 px-4">
              <h2 className="text-2xl font-medium capitalize my-5">
                {categoryItem?.categoryData?.name}
              </h2>

              {/* <div className="flex justify-center items-center gap-10 flex-wrap my-10 py-10">
                {products?.products
                  ?.filter(
                    (product) =>
                      product?.itemData?.categories[0]?.id === categoryItem?.id
                  )
                  .map((filteredProduct) => (
                    <ProductCard
                      categoryItem={categoryItem}
                      product={filteredProduct}
                      key={filteredProduct?.id}
                    />
                  ))}
              </div> */}

              <div className="flex justify-center items-center gap-5 flex-wrap">
                {products?.products
                  ?.filter((product) => {
                    const categories = product?.itemData?.categories;
                    return (
                      Array.isArray(categories) &&
                      categories[0]?.id === categoryItem?.id
                    );
                  })
                  .map((filteredProduct) => (
                    <ProductCard
                      categoryItem={categoryItem}
                      product={filteredProduct}
                      key={filteredProduct?.id}
                    />
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Menu;
