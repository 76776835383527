import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";

import moment from "moment";
import toast from "react-hot-toast";
import { useCreateSchedule, useUpdateSchedule } from "../../../features/schedule/schedule-service";
import { useSelector } from "react-redux";

const { TextArea } = Input;

const ScheduleForm = ({ onClose, selectedData, isUpdateModal }) => {
  const [isLoading, setIsLoading] = useState(false);

  const createMutation = useCreateSchedule();
  const UpdateMutation = useUpdateSchedule();

  const [form] = Form.useForm();
  
  const handleShiftChange = (e) => {
    const shift = e.target.value;
    if (shift === "morning") {
      form.setFieldsValue({
        startTime: "9:00 AM",
        endTime: "5:00 PM",
      });
    } else if (shift === "night") {
      form.setFieldsValue({
        startTime: "5:00 PM",
        endTime: "2:00 AM",
      });
    }
  };

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (selectedData && isUpdateModal) {
      form.setFieldsValue({
        title: selectedData[0]?.title ?? null,
        notes: selectedData[0]?.notes ?? null,
        date: selectedData[0]?.date ? moment(selectedData[0].date) : null,
        startTime: selectedData[0]?.startTime ?? null,
        endTime: selectedData[0]?.endTime ?? null,
        employee: selectedData[0]?.employee ?? null
      });
    }
  }, [selectedData, isUpdateModal]);

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log(values, "values");

    try {
      const data = {
        title: values.title ?? null,
        notes: values.notes ?? null,
        date: values.date ?? null,
        startTime: values.startTime ?? null,
        endTime: values.endTime ?? null,
        shift: values.shift ?? null ,
        employee: user?.EmployeeRef ?? null
      };

      try {
        if (isUpdateModal && selectedData) {
          await UpdateMutation.mutateAsync({
            apiUrl: `/api/schedule/${selectedData[0]?._id}`,
            data,
          });

          toast.success("Schedule Updated Successfully!");
        } else {
          await createMutation.mutateAsync({
            apiUrl: "/api/schedule",
            data,
          });

          toast.success("Schedule Created Successfully!");
        }
        form.resetFields();
        onClose();
      } catch (error) {
        toast.error("Error creating Schedule");
      }
    } catch (error) {
      toast.error("Error creating Schedule");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <Form
        form={form}
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">
          Schedule Information
        </h1>
        <div className="m-auto flex w-[100%] justify-between gap-4 pt-10">
          <div className="w-full">
            <Form.Item
              name="title"
              label="Title"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Title" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="notes"
              label="Notes"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Notes" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="date"
              label="Date"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <DatePicker disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="shift"
              label="Shift"
              rules={[{ required: true, message: "Please select a shift!" }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Radio.Group onChange={handleShiftChange} disabled={isLoading}>
                <Radio value="morning">Morning (9:00 AM - 5:00 PM)</Radio>
                <Radio value="night">Afternoon (5:00 PM - 2:00 AM)</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="startTime"
              label="Start Time"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: "Start time is required!" }]}
            >
              <Input placeholder="Start Time" disabled={true} />
            </Form.Item>

            <Form.Item
              name="endTime"
              label="End Time"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: "End time is required!" }]}
            >
              <Input placeholder="End Time" disabled={true} />
            </Form.Item>
          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button
              className="!bg-primary !text-white"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ScheduleForm;
