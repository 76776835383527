import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";

// import moment from "moment";
// import dayjs from "dayjs";

import {
  useAllEmployees,
  useCreateEmployee,
  useUpdateEmployee,
} from "../../features/Employee/employee-service";
import toast from "react-hot-toast";

const { TextArea } = Input;

const EmployeeForm = ({ onClose, selectedData, isUpdateModal }) => {
  //    const {
  //       data: employeesResponse,
  //       isError,
  //     } = useAllEmployees("/api/employees");

  const [isLoading, setIsLoading] = useState(false);

  const createMutation = useCreateEmployee();
  const UpdateMutation = useUpdateEmployee();

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedData && isUpdateModal) {
      form.setFieldsValue({
        firstName: selectedData[0]?.firstName ?? null,
        lastName: selectedData[0]?.lastName ?? null,
        phoneNo: selectedData[0]?.phoneNo ?? null,
        email: selectedData[0]?.email ?? null,
        jobTitle: selectedData[0]?.jobTitle ?? null,
      });
    }
  }, [selectedData, isUpdateModal]);

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log(values, "values");

    try {
      const data = {
        firstName: values.firstName ?? null,
        lastName: values.lastName ?? null,
        phoneNo: values.phoneNo ?? null,
        email: values.email ?? null,
        jobTitle: values.jobTitle ?? null,
      };

      try {
        if (isUpdateModal && selectedData) {
          await UpdateMutation.mutateAsync({
            apiUrl: `/api/employee/${selectedData[0]?._id}`,
            data,
          });

          toast.success("Employee Updated Successfully!");
        } else {
          await createMutation.mutateAsync({
            apiUrl: "/api/employee",
            data,
          });

          toast.success("Employee Created Successfully!");
        }
        form.resetFields();
        onClose();
      } catch (error) {
        toast.error("Error creating Employee");
      }
    } catch (error) {
      toast.error("Error creating Employee");
    } finally {
      setIsLoading(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <Form
        form={form}
        // layout="horizontal"
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">
          Employee Information
        </h1>
        <div className="m-auto flex w-[100%] justify-between gap-4 pt-10">
          <div className="w-full">
            <Form.Item
              name="firstName"
              label="First Name"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="First Name" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="lastName"
              label="Last Name"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Last Name" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="phoneNo"
              label="Phone Number"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Phone Number" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Email" disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name="jobTitle"
              label="Role"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              required
            >
              <Select
                placeholder="Role"
                style={{ width: "100%" }}
                allowClear
                options={[
                  { label: "Manager", value: "Manager" },
                  {
                    label: "Baker",
                    value: "Baker",
                  },
                  { label: "Cashier", value: "Cashier" },
                  { label: "Chef", value: "Chef" },
                  { label: "Cleaner", value: "Cleaner" },
                ]}
                disabled={isLoading}
              />
            </Form.Item>
          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button
              className="!bg-primary !text-white"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default EmployeeForm;
