import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GET_ATTENDANCE } from "./attendance-types";
import { create, deleteData, fetchAll } from "../../Apis/Apis";

//Get attendance
export const useGetAttendance = (apiUrl) => {
    return useQuery({
        queryKey: [GET_ATTENDANCE],
        queryFn: () => fetchAll(apiUrl)
    });
};

//Create/Mark attendance
export const useMarkAttendance = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: create,
        onMutate: async (newAttendance) => {
        },
        onError: (error, newAttendance, context) => {
            console.error("Error marking attendance:", error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(GET_ATTENDANCE);
        },
        onSettled: () => {
            queryClient.invalidateQueries(GET_ATTENDANCE);
        },
    });
};


//Delete Attendance
export const useDeleteAttendance = () => {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: deleteData,
      onMutate: async (attendanceId) => {
      },
      onError: (error, attendanceId, context) => {
        console.error("Error deleting attendance:", error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(GET_ATTENDANCE);
      },
      onSettled: () => {
        queryClient.invalidateQueries(GET_ATTENDANCE);
      },
    });
  };
  
