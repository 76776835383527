import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "@ag-grid-community/react";
import {
  useDeleteSchedule,
  useUpdateScheduleStatus,
} from "../../../features/schedule/schedule-service";
import { GlobalModalV1 } from "../../../component/GloobalModal.jsx";
import ScheduleForm from "./ScheduleForm";
import { formatedDate } from "../../../utils/data.js";
import ApproveBtn from "../../../component/ApproveBtn.jsx";
import EditBtn from "../../../component/EditBtn.jsx";
import DeleteBtn from "../../../component/DeleteBtn.jsx";
import RejectBtn from "../../../component/RejectBtn.jsx";
import ActionModal from "../../../component/ActionModal.jsx";

const ScheduleTable = ({ currentData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [modalData, setModalData] = useState({
    isOpen: false,
    title: "",
    content: "",
    action: null, // Function to execute when confirmed
  });

  const openModal = (title, content, action) => {
    setModalData({ isOpen: true, title, content, action });
  };

  const closeModal = () => {
    setModalData({ ...modalData, isOpen: false });
  };

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const createDeleteMutation = useDeleteSchedule();
  const createUpdateScheduleMutation = useUpdateScheduleStatus();

  const handleUpdate = () => {
    const filter = currentData?.filter((item) => item?._id === selectedIdEdit);
    setSelectedData(filter);
    setSelectedIdEdit(null);
  };

  useEffect(() => {
    if (selectedId) {
      handleDelete();
    }
    if (selectedIdEdit) {
      handleUpdate();
    }
  }, [selectedId, selectedIdEdit]);

  // Delete Schedule
  const handleDelete = (id) => {
    openModal(
      "Delete Schedule",
      "Are you sure you want to delete this schedule?",
      () =>
        createDeleteMutation.mutate(
          { apiUrl: `/api/schedule/${id}` },
          {
            onSuccess: () => {
              setSelectedId(null);
              toast.success("Schedule Deleted Successfully");
            },
            onError: (error) => {
              console.error("Error deleting Schedule", error);
              toast.error("Error deleting Schedule");
            },
          }
        )
    );
  };

  // Update Schedule Status
  const handleStatus = (id, status) => {
    openModal(
      "Update Schedule Status",
      `Are you sure you want to update the status to ${status}?`,
      () =>
        createUpdateScheduleMutation.mutate(
          { apiUrl: `/api/schedule-status/${id}`, data: { status: status } },
          {
            onSuccess: () => {
              toast.success("Schedule Status Updated Successfully");
            },
            onError: (error) => {
              console.error("Error updating Schedule Status", error);
              toast.error("Error updating Schedule Status");
            },
          }
        )
    );
  };

  const handleConfirmAction = () => {
    if (modalData.action) {
      modalData.action();
    }
    closeModal();
  };

  // //Delete Schedule
  // const handleDelete = () => {
  //   createDeleteMutation.mutate(
  //     { apiUrl: `/api/schedule/${selectedId}` },
  //     {
  //       onSuccess: () => {
  //         setSelectedId(null);
  //         toast.success("Schedule Deleted Successfully");
  //       },
  //       onError: (error) => {
  //         console.error("Error deleting Schedule", error);
  //         toast.error("Error deleting Schedule");
  //       },
  //     }
  //   );
  // };

  // //Update Schedule Status
  // const handleStatus = (id, status) => {
  //   console.log("Status: ", status);

  //   createUpdateScheduleMutation.mutate(
  //     { apiUrl: `/api/schedule-status/${id}`, data: { status: status } },
  //     {
  //       onSuccess: () => {
  //         toast.success("Schedule Approved Successfully");
  //       },
  //       onError: (error) => {
  //         console.error("Error approving Schedule", error);
  //         toast.error("Error approving Schedule");
  //       },
  //     }
  //   );
  // };

  //Action Btn's
  const btnComponent = (props) => {
    const handle = () => {
      setSelectedIdEdit(props?.data?._id);
      onOpen();
      console.log(props?.data?._id);
    };

    const { status } = props.data;
    // console.log("Status===>", status);

    return (
      <>
        {status === "pending" && (
          <div className="flex gap-5 pt-2">
            <ApproveBtn
              onClick={() => handleStatus(props.data?._id, "approved")}
            />
            <EditBtn onClick={handle} />
            <RejectBtn
              onClick={() => handleStatus(props.data._id, "rejected")}
            />
          </div>
        )}

        {(status === "approved" || status === "rejected") && (
          <div className="flex gap-5 pt-2">
            <DeleteBtn onClick={() => setSelectedId(props.data._id)} />
          </div>
        )}
      </>
    );
  };

  const columns = [
    {
      headerName: "ID",
      field: "id",
    },
    {
      headerName: "Title",
      field: "title",
    },
    {
      headerName: "Notes",
      field: "notes",
    },
    {
      headerName: "Date",
      valueGetter: (params) => formatedDate(params.data.date),
    },
    {
      headerName: "Start Time",
      field: "startTime",
    },
    {
      headerName: "End Time",
      field: "endTime",
    },
    {
      headerName: "status",
      field: "status",
    },
    {
      headerName: "Action",
      cellRenderer: btnComponent,
      pinned: "right",
    },
  ];

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,
    floatingFilter: true,
    flex: 1,
  };

  return (
    <div>
      <div className="ag-theme-quartz mt-6" style={{ height: 500 }}>
        <AgGridReact
          modules={[ClientSideRowModelModule]}
          rowData={currentData}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[10, 25, 50]}
        />
      </div>
      <GlobalModalV1 isOpen={isOpen} onClose={onClose} width={"800px"}>
        <ScheduleForm
          onClose={onClose}
          isUpdateModal={true}
          selectedData={selectedData}
        />
      </GlobalModalV1>

      <ActionModal
        centered
        isOpen={modalData.isOpen}
        onClose={closeModal}
        onConfirm={handleConfirmAction}
        okText={"Yes"}
        content={modalData.content}
        title={modalData.title}
      />
    </div>
  );
};

export default ScheduleTable;
