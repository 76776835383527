import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Button, Checkbox, Modal, Select, Collapse } from "antd";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../features/cart/action";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { MdClose } from "react-icons/md";
// import { NextArrow, PrevArrow } from "../utils";

// import { Button, Modal } from "flowbite-react";

// Left Arrow
const PrevArrow = ({ onClick }) => {
  return (
    <button
      className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-zinc-100 p-2 rounded-full z-10 shadow hover:bg-zinc-200"
      onClick={onClick}
    >
      <FaChevronLeft size={16} className="text-xl text-zinc-600" />
    </button>
  );
};

// Right Arrow
const NextArrow = ({ onClick }) => {
  return (
    <button
      className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-zinc-100 p-2 rounded-full z-10 shadow hover:bg-zinc-200"
      onClick={onClick}
    >
      <FaChevronRight size={16} className="text-xl text-zinc-600" />
    </button>
  );
};

const ProductCard = ({ product, categoryItem, key }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [modifiedProduct, setModifiedProduct] = useState({
    ...product,
    selections: {}, // Will store all selections with their group names
    totalAddons: [], // Will store all selected add-ons
    totalPrice: product?.itemData?.variations[0]?.itemVariationData?.priceMoney?.amount || 0
  });
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const navigate = useNavigate();

  // console.log("product", product);
  // console.log("Product keys===>", product);

  const { register, handleSubmit, setValue, watch, } = useForm();
  const selectedToasting = watch("toasting");
  const selectedChoices = watch("choices");
  const selectedAddOns = watch("addOns");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };



  const handleChange = (field, value = {}) => {
    setValue(field, value);

    setModifiedProduct(prev => {
      const newSelections = {
        ...prev.selections,
        [field]: value
      };



      // Calculate total price
      let totalPrice = Number(product?.itemData?.variations[0]?.itemVariationData?.priceMoney?.amount || 0);

      // Add up prices from all selections
      Object.entries(newSelections).forEach(([groupName, selection]) => {
        if (Array.isArray(selection)) {
          // Handle multiple selections (like add-ons)
          selection.forEach(item => {
            if (item.price) totalPrice += Number(item.price);
          });
        } else if (typeof selection === 'string') {
          // Handle single selections (like radio buttons)
          const modifierList = product.itemData.modifierLists.find(
            list => list.modifierListData.name === groupName
          );
          const modifier = modifierList?.modifierListData.modifiers.find(
            mod => mod.modifierData.name === selection
          );
          if (modifier?.modifierData?.priceMoney?.amount) {
            totalPrice += Number(modifier.modifierData.priceMoney.amount);
          }
        }
      });

      return {
        ...prev,
        selections: newSelections,
        totalPrice
      };
    });
  };

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    // addToCart({ ...data, totalPrice });
    setIsShowModal(false);
  };
  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const handleAddToCart = () => {
    dispatch(addToCart({
      ...modifiedProduct,
      quantity: 1,
      itemTotal: modifiedProduct.totalPrice
    }));

    window.dispatchEvent(new Event("cartUpdate"));

    toast.success(`${product?.itemData?.name} added to cart!`, {
      duration: 3000,
      position: "top-center",
    });

    setIsShowModal(false);
  };



  return (
    <div className="flex flex-col max-w-[400px] min-h-[130px]  rounded-[8px] border  border-[#e6e3e3] bg-white cursor-pointer">
      <Slider {...settings}>
        <div className="w-[400px] overflow-hidden object-contain h-[225px] bg-center">
          <img
            src={product?.image}
            alt={product?.title}
            className="w-full h-full object-contain rounded-t-xl transition-transform duration-300 ease-in-out hover:scale-110"
          />
        </div>
        <div className="w-[400px] overflow-hidden object-contain h-[225px] bg-center">
          <img
            src={product?.image}
            alt={product?.title}
            className="w-full h-full object-contain rounded-t-xl filter mix-blend-screen transition-transform duration-300 ease-in-out hover:scale-110"
          />
        </div>
        <div className="w-[400px] overflow-hidden object-contain h-[225px] bg-center">
          <img
            src={product?.image}
            alt={product?.title}
            className="w-full h-full object-contain rounded-t-xl transition-transform duration-300 ease-in-out hover:scale-110"
          />
        </div>
      </Slider>

      {/* Content Section */}
      <div className="flex justify-between flex-col p-6 space-y-4 ">
        <h3 className="font-semibold text-xl capitalize text-gray-800">
          {product?.itemData?.name}
        </h3>

        <p className="line-clamp-2 text-gray-600 text-sm font-normal">
          {product?.itemData?.description || ""}
        </p>

        {/* Action Button */}
        <div className="flex justify-between items-center">
          <span className="text-yellow-600 text-xl">
            $
            {product?.itemData?.variations[0]?.itemVariationData?.priceMoney
              ?.amount || "N/A"}
          </span>
          <button
            onClick={() => setIsShowModal(true)}
            className="group bg-yellow-500 hover:bg-yellow-600 text-white rounded-full px-6 py-2.5 flex items-center gap-2 transition-all duration-300"
          >
            <IoMdAdd className="text-xl group-hover:rotate-90 transition-transform duration-300" />
            <span>Add to Order</span>
          </button>
        </div>
      </div>

      {/* Modal */}
      <Modal
        centered
        open={isShowModal}
        onCancel={() => setIsShowModal(false)}
        closeIcon={
          <MdClose
            size={30}
            className="bg-zinc-300 text-3xl text-zinc-800 hover:text-red-500  transition-all duration-300 rounded-md"
          />
        }
        width="90%"
        className="product-modal"
        style={{
          maxWidth: "600px",
          margin: "0 auto",
          top: "0",
          bottom: "0",
        }}
        footer={
          <div className="flex flex-wrap justify-center gap-4 pb-4">
            <button
              key="addToCart"
              onClick={handleAddToCart}
              className="bg-yellow-500 text-white w-48 px-6 py-3 rounded-full hover:bg-yellow-600 transition-all duration-300 font-medium"
            >
              Add To Cart - ${modifiedProduct.totalPrice}
            </button>
            {cart.length > 0 && (
              <button
                key="close"
                onClick={() => navigate("/checkout")}
                className="bg-blue-500 text-white w-48 px-6 py-3 rounded-full hover:bg-blue-600 transition-all duration-300 font-medium"
              >
                Go to checkout
              </button>
            )}
          </div>
        }
      >
        {/* Modal Content */}
        <div className="bg-white rounded-xl overflow-hidden">
          <Slider {...settings}>
            <div className="w-[400px] overflow-hidden object-cover h-[300px] bg-center">
              <img
                src={product?.image}
                alt={product?.title}
                className="w-full h-full object-contain rounded-t-2xl"
              />
            </div>
            <div className="w-[400px] overflow-hidden object-cover h-[300px] bg-center">
              <img
                src={product?.image}
                alt={product?.title}
                className="w-full h-full object-contain rounded-t-2xl"
              />
            </div>
            <div className="w-[400px] overflow-hidden object-cover h-[300px] bg-center">
              <img
                src={product?.image}
                alt={product?.title}
                className="w-full h-full object-contain rounded-t-2xl"
              />
            </div>
          </Slider>

          <div className="mt-8 space-y-6 max-h-[400px] overflow-y-auto px-6">
            <div className="space-y-4">
              <h2 className="text-2xl font-bold text-gray-800">
                {product?.itemData?.name}
              </h2>

              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="text-xl font-semibold text-gray-800">
                  Price:{" "}
                  <span className="text-yellow-600">
                    ${(product?.itemData?.variations[0]?.itemVariationData?.priceMoney?.amount || 0)}
                  </span>
                </p>
              </div>

              {/* Options Sections */}
              <div className="spaxce-y-6 pt-4 pb-5">
                {product?.itemData?.modifierLists.map((modifierList, index) => {

                  if (!modifierList.modifierListData.name.includes("ADD-ONS")) {
                    return (
                      <>
                        <div className="space-y-3">
                          <p className="pt-4 text-lg font-semibold text-gray-800">
                            {modifierList.modifierListData.name}
                          </p>
                          {/* Desktop Select */}
                          <div className="hidden md:block">
                            <Select
                              showSearch
                              mode={modifierList.modifierListData.selectionType === "SINGLE" ? undefined : "multiple"}
                              placeholder={`Select ${modifierList.modifierListData.name.toLowerCase()}`}
                              optionFilterProp="label"
                              onChange={(value) => handleChange(modifierList.modifierListData.name, {
                                name: value,
                                price: modifierList?.modifierListData?.modifiers?.find(item => item.modifierData.name === value)?.modifierData?.priceMoney?.amount || 0
                              })}
                              style={{ width: "100%" }}
                              className="rounded-lg"
                              options={modifierList?.modifierListData?.modifiers?.map((item) => ({
                                label: (
                                  <div className="flex justify-between">
                                    <span>{item.modifierData.name}</span>
                                    {item.modifierData.priceMoney?.amount > 0 && (
                                      <span className="text-gray-500">
                                        (+${item.modifierData.priceMoney.amount})
                                      </span>
                                    )}
                                  </div>
                                ),
                                value: item.modifierData.name,
                              }))}
                            />
                          </div>

                          {/* Mobile Select */}
                          <select
                            className="md:hidden w-full p-3 border border-gray-300 rounded-lg bg-white"
                            onChange={(e) => {
                              const value = modifierList.modifierListData.selectionType === "SINGLE"
                                ? e.target.value
                                : Array.from(e.target.selectedOptions, option => option.value);
                              handleChange(modifierList.modifierListData.name, value);
                            }}
                            multiple={modifierList.modifierListData.selectionType !== "SINGLE"}
                          >
                            <option value="" disabled selected>
                              Select {modifierList.modifierListData.name.toLowerCase()}
                            </option>
                            {modifierList?.modifierListData?.modifiers?.map((item) => (
                              <option
                                key={item.id}
                                value={item.modifierData.name}
                              >
                                {item.modifierData.name}
                                {item.modifierData.priceMoney?.amount > 0
                                  ? ` (+$${item.modifierData.priceMoney.amount})`
                                  : ''
                                }
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    )


                  }
                  if (modifierList.modifierListData.name.includes("ADD-ONS")) {
                    return (
                      <div className="space-y-4 pt-4">
                        <Collapse
                          className="bg-white border-zinc-200"
                          expandIcon={({ isActive }) => (
                            <div className={`transition-transform duration-200 ${isActive ? 'rotate-90' : ''}`}>
                              <FaChevronRight className="mt-2 ml-2 text-gray-500" />
                            </div>
                          )}
                          items={[
                            {
                              key: modifierList.id,
                              label: (
                                <div className="flex justify-between items-center w-full">
                                  <div className="flex items-center gap-2">
                                    <span className="text-lg font-semibold text-gray-800">
                                      {modifierList?.modifierListData?.name}
                                    </span>
                                  </div>
                                  <span className="text-sm text-gray-500">
                                    {watch(modifierList.modifierListData.name)?.length || 0} selected
                                  </span>
                                </div>
                              ),
                              children: (
                                <Checkbox.Group
                                  onChange={(selectedValues) => {
                                    const selectedAddOns = selectedValues.map(value => {
                                      const addon = modifierList.modifierListData.modifiers.find(
                                        mod => mod.modifierData.name === value
                                      );
                                      return {
                                        name: addon.modifierData.name,
                                        price: addon.modifierData.priceMoney?.amount || 0,
                                        id: addon.id
                                      };
                                    });
                                    handleChange(modifierList.modifierListData.name, selectedAddOns);
                                  }}
                                  className="w-full"
                                >
                                  <div className="grid gap-4 w-full pt-2">
                                    {modifierList?.modifierListData?.modifiers?.map((addon, index) => (
                                      <div
                                        key={addon.id || index}
                                        className="flex w-full justify-between items-start border-b border-zinc-200 pb-2 space-y-1"
                                      >
                                        <Checkbox
                                          value={addon.modifierData.name}
                                          className="text-base"
                                          disabled={
                                            modifierList.modifierListData.selectionType === "SINGLE" &&
                                            watch(modifierList.modifierListData.name)?.length >= 1 &&
                                            !watch(modifierList.modifierListData.name)?.find(item => item.name === addon.modifierData.name)
                                          }
                                        >
                                          <span className="text-gray-800 font-medium">
                                            {addon.modifierData.name}
                                          </span>
                                        </Checkbox>
                                        <span className="text-gray-500 text-sm">
                                          (+ ${addon.modifierData.priceMoney?.amount || '0.00'})
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                </Checkbox.Group>
                              )
                            }
                          ]}

                        />
                      </div>
                    );
                  }
                }
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProductCard;
