import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  decreaseQuantity,
  increaseQuantity,
  removeFromCart,
} from "../features/cart/action";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineShoppingBag } from "react-icons/md";

const ItemCart = ({ color }) => {
  const [isShowCard, setIsShowCard] = useState(false);
  const cart = useSelector((state) => state.cart.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsShowCard(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleIncrement = (id) => {
    dispatch(increaseQuantity(id));
  };

  const handleDecrement = (id) => {
    dispatch(decreaseQuantity(id));
  };

  const handleDeleteItem = (id) => {
    dispatch(removeFromCart(id));
  };

  const handleRoute = () => {
    setIsShowCard(false);
    navigate("/checkout");
  };

  return (
    <div className="relative z-50" ref={dropdownRef}>
      {/* Cart Icon */}
      <div
        className="relative cursor-pointer p-2  rounded-full transition-all duration-200"
        onClick={() => setIsShowCard(!isShowCard)}
      >
        {cart.length > 0 && (
          <span className="absolute -top-1 -right-1 w-5 h-5 flex items-center justify-center text-xs font-bold text-white bg-yellow-500 rounded-full">
            {cart.length}
          </span>
        )}
        <MdOutlineShoppingBag size={24} color={color} />
      </div>

      {/* Dropdown Cart */}
      {isShowCard && (
        <div className="absolute top-full right-0 mt-2 bg-white border shadow-2xl border-zinc-100 rounded-2xl w-96">
          <h3 className="font-semibold text-lg p-4 border-b border-gray-200 text-gray-800">
            Your Cart ({cart?.length})
          </h3>

          {/* Scrollable Cart Items */}
          <div className="max-h-[40vh] overflow-y-auto p-4">
            {cart.length > 0 ? (
              <ul className="space-y-4">
                {cart.map((item, index) => (
                  <li
                    key={index}
                    className="flex flex-col bg-gray-50 rounded-xl p-3 transition-all duration-200 hover:shadow-md"
                  >
                    <div className="flex justify-between items-start gap-3">
                      <div className="flex gap-3">
                        <img
                          src={item.image}
                          alt={item.name}
                          className="h-16 w-16 object-cover rounded-lg"
                        />
                        <div>
                          <p className="font-medium text-gray-800">
                            {item?.itemData?.name}
                          </p>
                          <p className="text-sm text-gray-500">
                            {
                              item?.itemData?.variations[0]?.itemVariationData
                                ?.name
                            }
                          </p>
                          <p className="font-semibold text-yellow-600 mt-1">
                            $
                            {
                              item?.itemData?.variations[0]?.itemVariationData
                                ?.priceMoney?.amount
                            }
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between items-center mt-3 pt-3 border-t border-gray-200">
                      <div className="flex items-center space-x-2">
                        <button
                          onClick={() => handleDecrement(item.id)}
                          className="w-8 h-8 bg-white rounded-full flex items-center justify-center text-gray-600 hover:bg-gray-100 transition-colors"
                        >
                          -
                        </button>
                        <span className="w-8 text-center font-medium">
                          {item.quantity}
                        </span>
                        <button
                          onClick={() => handleIncrement(item.id)}
                          className="w-8 h-8 bg-white rounded-full flex items-center justify-center text-gray-600 hover:bg-gray-100 transition-colors"
                        >
                          +
                        </button>
                      </div>

                      <button
                        onClick={() => handleDeleteItem(item.id)}
                        className="text-red-500 hover:text-red-600 transition-colors p-2"
                      >
                        <FaRegTrashAlt />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500 text-center py-8 font-normal">
                Your cart is empty
              </p>
            )}
          </div>

          {/* Fixed Bottom Section */}
          {cart?.length > 0 && (
            <div className="border-t border-gray-200 p-4 bg-white sticky bottom-0 rounded-b-2xl">
              <div className="flex justify-between items-center mb-3">
                <span className="font-medium text-gray-600">
                  Total (with delivery)
                </span>
                <span className="font-bold text-lg">
                  $
                  {(
                    cart.reduce(
                      (total, item) =>
                        total +
                        item.itemData?.variations[0]?.itemVariationData
                          ?.priceMoney?.amount *
                          item.quantity,
                      0
                    ) + 5
                  ).toFixed(2)}
                </span>
              </div>

              <button
                onClick={handleRoute}
                className="w-full bg-yellow-500 hover:bg-yellow-600 text-white font-medium py-3 px-4 rounded-full transition-colors duration-200"
              >
                Proceed to Checkout
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ItemCart;
