import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL || "http://localhost:5000";

export const register = ({ name, email, password, role }) => {
  return axios.post(
    `${API_URL}/api/user/create`,
    {
      name,
      email,
      password,
      role,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
  );
};

export const login = ({ email, password }) => {
  return axios.post(
    `${API_URL}/api/user/login`,
    {
      email,
      password,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
  );
};

export const refreshToken = (refreshToken) => {
  return axios.post(
    `${API_URL}/api/refresh`,
    {
      token: refreshToken,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
  );
};

export const sendPasswordResetEmail = (email) => {
  return axios.post(
    `${API_URL}/api/send-password-reset-email`,
    {
      email,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
  );
};
export const resetPassword = (token, password) => {
  return axios.post(
    `${API_URL}/api/reset-password`,
    {
      newPassword: password,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {
        token: token,
      },
    },
  );
};

export const logout = () => {
  localStorage.removeItem("persist:auth");
  
};

const authService = {
  register,
  login,
  refreshToken,
  logout,
};

export default authService;
