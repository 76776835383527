import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { GET_EMPLOYEE, GET_EMPLOYEE_SCHEDULE } from "./employee-types"
import { create, deleteData, fetchAll, getById, update } from "../../Apis/Apis"
import axios from "axios";



//Gett all employees
export const useAllEmployees = (apiUrl) => {
    return useQuery({
        queryKey: [GET_EMPLOYEE],
        queryFn: () => fetchAll(apiUrl)
    })
};


//Get employee by id
export const useFetchEmployeeById = (apiUrl) => {
    return useQuery({
        queryKey: [GET_EMPLOYEE],
        queryFn: () => getById(apiUrl),
        enabled: !!apiUrl,
    });
};


//Create employee
export const useCreateEmployee = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: create,
        onMutate: async (newEmployee) => {
            queryClient.invalidateQueries(GET_EMPLOYEE);
        },
        onError: (error, newEmployee, context) => {
            console.log("Error in create employee:", error)
        },
        onSuccess: (data, newEmployee, context) => {
            queryClient.invalidateQueries(GET_EMPLOYEE);
        },
        onSettled: () => {
            queryClient.invalidateQueries(GET_EMPLOYEE);
        }
    })
}


//Edit/Update employee
export const useUpdateEmployee = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: update,
        onMutate: async (updatedEmployee) => {
            queryClient.invalidateQueries(GET_EMPLOYEE);
        },
        onError: (error, updatedEmployee, context) => {
            console.log("Error in update employee", error)
        },
        onSuccess: (data, updatedEmployee, context) => {
            queryClient.invalidateQueries(GET_EMPLOYEE);
        },
        onSettled: () => {
            queryClient.invalidateQueries(GET_EMPLOYEE);
        }
    })
}


//Delete Employee
export const useDeleteEmployee = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: deleteData,
        onMutate: async (employeeID) => {
            queryClient.invalidateQueries(GET_EMPLOYEE);
        },
        onError: (error, employeeID, context) => {
            console.log("Error in delete employee", error)
        },
        onSuccess: (data, employeeID, context) => {
            queryClient.invalidateQueries(GET_EMPLOYEE);
        },
        onSettled: () => {
            queryClient.invalidateQueries(GET_EMPLOYEE);
        }
    })
}


//Get schedules by employee id
export const useSchedulesByEmployeeId = (apiUrl) => {
    return useQuery({
        queryKey: ['GET_SCHEDULES_BY_EMPLOYEE'],
        queryFn: () => getById(apiUrl),
        enabled: !!apiUrl
    })
}