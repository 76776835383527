const CustomToolbar = ({ label, onNavigate, onView }) => {
  return (
    <div className="flex justify-between items-center mb-4 bg-gray-50 p-4 rounded-lg shadow-sm">
      <button
        onClick={() => onNavigate("PREV")}
        className="px-4 py-2 bg-gray-200 text-gray-700 hover:bg-gray-300 rounded-lg"
      >
        Prev
      </button>
      <h2 className="text-lg font-semibold text-gray-800">{label}</h2>
      <div className="flex space-x-2">
        <button
          onClick={() => onView("month")}
          className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded-lg"
        >
          Month
        </button>
        <button
          onClick={() => onView("week")}
          className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded-lg"
        >
          Week
        </button>
        <button
          onClick={() => onView("day")}
          className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded-lg"
        >
          Day
        </button>
      </div>
      <button
        onClick={() => onNavigate("NEXT")}
        className="px-4 py-2 bg-gray-200 text-gray-700 hover:bg-gray-300 rounded-lg"
      >
        Next
      </button>
    </div>
  );
};

export default CustomToolbar;
