import moment from "moment";
import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import CustomToolbar from "../../../component/CalendarToolbar";

const AttendanceCalendar = ({ mode, events, handleSelectSlot }) => {
  const localizer = momentLocalizer(moment);

  return (
    <Calendar
      mode={mode}
      localizer={localizer}
      events={events}
      selectable
      defaultView="month"
      views={["month", "week", "day"]}
      style={{ height: "80vh" }}
      onSelectSlot={handleSelectSlot}
      eventPropGetter={(event) => ({
        style: {
          backgroundColor:
            event.title === "Present" ? "#34D399" : "transparent",
          color: "white",
          borderRadius: "0.25rem",
        },
      })}
      dayPropGetter={(date) => {
        const isToday = moment(date).isSame(moment(), "day");
        return {
          style: {
            backgroundColor: isToday ? "#EFF6FF" : "",
            border: isToday ? "2px solid #3B82F6" : "",
          },
        };
      }}
      components={{
        toolbar: CustomToolbar,
      }}
    />
  );
};

export default AttendanceCalendar;
