import React, { useEffect } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";

const ActionModal = ({
  isOpen,
  onClose,
  title = "Confirm",
  content = "Are you sure you want to proceed?",
  okText = "Confirm",
  cancelText = "Cancel",
  onConfirm,
  onCancel,
  centered,
}) => {
  useEffect(() => {
    if (isOpen) {
      Modal.confirm({
        title,
        centered,
        icon: <ExclamationCircleOutlined />,
        content,
        okText,
        cancelText,
        onOk: () => {
          if (onConfirm) onConfirm();
          onClose();
        },
        onCancel: () => {
          if (onCancel) onCancel();
          onClose();
        },
      });
    }
  }, [
    isOpen,
    title,
    content,
    okText,
    cancelText,
    onConfirm,
    onCancel,
    onClose,
  ]);

  return null;
};

export default ActionModal;
