import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { menuItems } from "../contants";
import { useSelector } from "react-redux";
import { IoLogOutSharp } from "react-icons/io5";
import { logout } from "../features/auth/authService";

const SidebarCustom = ({ subpage }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const isAdminAuth = useSelector((state) => state.auth.isAdminAuth);
  const isEmployeeAuth = useSelector((state) => state.auth.isEmployeeAuth);

  //Handle Sidebar Menu Collapse
  const handleSidebarMenu = () => {
    setIsCollapsed(!isCollapsed);
  };

  //Logout
  const handleLogout = () => {
    logout();
    window.location.reload();
  };

  return (
    <div
      className={`no-scrollbar sticky top-0 h-screen overflow-y-auto bg-primary ease-in-out transition-all duration-300 rounded-3xl ${
        isCollapsed ? "w-72" : "w-28"
      } flex-shrink-0`}
    >
      <div
        className={`flex items-center p-4 ${
          isCollapsed ? "justify-between" : "justify-center"
        }`}
      >
        {isCollapsed && (
          <span className="text-lg font-bold">
            {isAdminAuth ? "Admin" : "Employee"}
          </span>
        )}
        <button
          onClick={handleSidebarMenu}
          className="hover:bg-[#D9D9D9]/50 duration-75 rounded-full p-2"
        >
          {isCollapsed ? (
            <MdOutlineKeyboardArrowLeft size={28} />
          ) : (
            <MdOutlineKeyboardArrowRight size={28} />
          )}
        </button>
      </div>
      <div className={`flex w-full flex-col justify-center items-center`}>
        <img src="/images/logo.svg" alt="logo" width={80} />
      </div>
      <div className="mt-4 space-y-2">
        {/* <div className="flex flex-col gap-2 py-4">
          {menuItems.map((menuItem, index) => (
            <Link
              to={`/admin/${menuItem.link}`}
              className={`flex items-center justify-start gap-3 font-semibold text-[#001219] duration-100 hover:bg-[#030405]/15 ${
                isCollapsed
                  ? "py-2 pl-10"
                  : "justify-center mx-6 py-3 rounded-lg"
              } ${subpage === menuItem.link && "bg-[#030405]/15 text-white"}`}
              key={index}
            >
              <menuItem.icon size={25} />
              {isCollapsed && (
                <p className="capitalize font-bold">{menuItem.menuLabel}</p>
              )}
            </Link>
          ))}
        </div> */}

        <div className="flex flex-col gap-2 py-4">
          {menuItems
            .filter((menuItem) => {
              if (isAdminAuth && menuItem.roles.includes("admin")) return true;
              if (isEmployeeAuth && menuItem.roles.includes("employee"))
                return true;
              return false;
            })
            .map((menuItem, index) => (
              <Link
                to={`/${isAdminAuth ? "admin" : "employee"}/${menuItem.link}`}
                className={`flex items-center justify-start gap-3 text-[#001219] duration-100 hover:bg-[#030405]/15 ${
                  isCollapsed
                    ? "py-2 pl-10"
                    : "justify-center mx-6 py-3 rounded-lg"
                } ${subpage === menuItem.link && "bg-[#030405]/15 text-white"}`}
                key={index}
              >
                <menuItem.icon size={25} />
                {isCollapsed && (
                  <p className="capitalize font-medium">{menuItem.menuLabel}</p>
                )}
              </Link>
            ))}
          <div
            className="flex items-center justify-start py-3 cursor-pointer pl-[45px] gap-3  text-[#001219] duration-100 hover:bg-[#030405]/15"
            onClick={() => handleLogout()}
          >
            <IoLogOutSharp size={25} />
            <p className="font-normal">Logout</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarCustom;
