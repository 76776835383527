import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  useAllEmployees,
  useDeleteEmployee,
} from "../../features/Employee/employee-service";
import EntriesDropdown from "../../component/DropDown.jsx";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "@ag-grid-community/react";
import { AiFillEdit } from "react-icons/ai";
import { LiaTimesSolid } from "react-icons/lia";
import { GlobalModalV1 } from "../../component/GloobalModal.jsx";
import EmployeeForm from "./EmployeeForm.jsx";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import TableSkeleton from "../../component/skeleton/TableSkeleton.jsx";

const EmployeeTable = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const navigate = useNavigate();

  //Get All Employees
  const {
    data: employeesResponse,
    isLoading,
    isError,
  } = useAllEmployees("/api/employees");

  console.log("Employee Response==>", employeesResponse);

  //Delete Employee Mutation
  const createDeleteMutation = useDeleteEmployee();

  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const rowsPerPage = 10;

  useEffect(() => {
    if (employeesResponse) {
      console.log("API Response:", employeesResponse);
      if (
        employeesResponse.employee &&
        Array.isArray(employeesResponse.employee)
      ) {
        setTableData(employeesResponse.employee);
      } 
    }
  }, [employeesResponse]);

  useEffect(() => {
    if (selectedId) {
      handleDelete();
      console.log(selectedId, "selectedId");
    }
    if (selectedIdEdit) {
      handleUpdate();
      console.log(selectedIdEdit, "selectedIdEdit");
    }
  }, [selectedId, selectedIdEdit]);

  if(isLoading) return <TableSkeleton />



  const totalPages = Math.ceil(tableData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;

  // Safeguard to ensure tableData is always an array
  const currentData = Array.isArray(tableData)
    ? tableData
        .slice(startIndex, startIndex + rowsPerPage)
        .map((item, index) => ({
          ...item,
          id: index + 1,
          attendance: item.attendance.length,
        }))
    : [];

  // console.log("CurrentData====>", currentData[0].attendance.length);

  // console.log("currentData===>", currentData);

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleUpdate = () => {
    const filter = currentData?.filter((item) => item?._id === selectedIdEdit);
    console.log(filter, "filterfilterfilter");
    setSelectedData(filter);
    setSelectedIdEdit(null);
  };

 

  const handleDelete = () => {
    createDeleteMutation.mutate(
      { apiUrl: `/api/employee/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null); // Triggers re-render safely
          toast.success("Employee Deleted Successfully"); // Use toast.success
        },
        onError: (error) => {
          console.error("Error deleting Employee", error);
          toast.error("Error deleting Employee"); // Use toast.error
        },
      }
    );
  };

  const viewEmployee = (id) => {
    console.log("view employee", id);

    const filterEmployee = currentData?.filter((item) => item?._id === id);

    if (filterEmployee?.length > 0) {
      const employeeData = filterEmployee[0];
      console.log("Employee Data Found:", employeeData);
      navigate(`/admin/employee-details/${employeeData?._id}`, {
        // state: { employeeData },
      });
    } else {
      console.log("No data found for the given ID");
    }
  };

  const btnComponent = (props) => {
    const handle = () => {
      setSelectedIdEdit(props?.data?._id);
      onOpen();
      console.log(props?.data?._id);
    };
    return (
      <div className="flex gap-5 pt-2">
        <button
          className={`rounded-full bg-gray-700 p-1 text-white`}
          onClick={() => viewEmployee(props.data?._id)}
        >
          <MdOutlineRemoveRedEye />
        </button>

        <button
          className="rounded-full bg-[#35f8fe] p-1 text-white"
          onClick={handle}
        >
          <AiFillEdit />
        </button>

        <button
          className="rounded-full bg-[#EE5D50] p-1 text-white"
          onClick={() => setSelectedId(props.data._id)}
        >
          <LiaTimesSolid />
        </button>
      </div>
    );
  };

  const columns = [
    {
      headerName: "ID",
      field: "id",
    },
    {
      headerName: "Employee",
      field: "firstName",
    },
    {
      headerName: "Email",
      field: "email",
    },
    {
      headerName: "Phone",
      field: "phoneNo",
    },
    {
      headerName: "Role",
      field: "jobTitle",
    },
    {
      headerName: "Attendance",
      field: "attendance",
    },
    {
      headerName: "Status",
      field: "status",
    },

    {
      headerName: "Action",
      cellRenderer: btnComponent,
      pinned: "right",
      // width: "100%",
    },
  ];

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,
    floatingFilter: true,
  };

  const items = [
    {
      label: "1st menu item",
      key: "1",
    },
    {
      label: "2nd menu item",
      key: "2",
    },
    {
      label: "3rd menu item",
      key: "3",
      danger: true,
    },
    {
      label: "4th menu item",
      key: "4",
      danger: true,
      disabled: true,
    },
  ];

  const menuProps = {
    items,
    onClick: (e) => console.log("Click", e),
  };

  const getDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching data</div>;

  return (
    <div>
      {/* <div className="flex justify-between mt-5">
        <EntriesDropdown />
        <div>
          Search: <input className="h-[40px] rounded-md" type="Search" />
        </div>
      </div>
      <table className="mt-8 w-[100%]">
        <thead>
          <tr className="h-[55px] bg-[#000000CC] text-white">
            <th scope="col" className="rounded-tl-[10px] px-4 text-left">
              ID #
            </th>
            <th scope="col" className="px-4 text-left">
              Customer
            </th>
            <th scope="col" className="px-4 text-left">
              Email
            </th>
            <th scope="col" className="px-4 text-left">
              Phone
            </th>
            <th scope="col" className="px-4 text-left">
              Role
            </th>
            <th scope="col" className="px-4 text-left">
              Attendance
            </th>
            <th scope="col" className="px-4 text-left">
              Status
            </th>
            <th scope="col" className="rounded-tr-[10px] px-4 text-left">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {currentData.map((row, index) => (
            <tr className="border-2 border-zinc-300" key={row._id}>
              <td className="px-4 py-4">{startIndex + index + 1}</td>
              <td className="px-4 py-4">{`${row.firstName} ${row.lastName}`}</td>
              <td className="px-4 py-4">{row.email || "N/A"}</td>
              <td className="px-4 py-4">{row.phoneNo || "N/A"}</td>
              <td className="px-4 py-4">{row.jobTitle || "N/A"}</td>
              <td className="px-4 py-4">{row.attendance.length || 0}</td>
              <td className="px-4 py-4">
                <span
                  className={`px-2 py-1 rounded-full text-sm ${
                    row.status === "active"
                      ? "bg-green-100 text-green-800"
                      : "bg-red-100 text-red-800"
                  }`}
                >
                  {row.status || "Inactive"}
                </span>
              </td>
              <td className="px-4 py-4">
                <div className="flex gap-2">
                 
                  <button
                    className="actionBtns bg-[#C92222] h-[40px] w-[40px] p-4 text-white rounded-[10px]"
                    onClick={() => setSelectedId(row._id)}
                  >
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 9L9 1M1 1L9 9"
                        stroke="#EDEDE9"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex items-center justify-between mt-4 p-1">
        <span>
          Page {currentPage} of {totalPages || 1}
        </span>
        <div className="flex gap-3">
          <button
            onClick={handlePrevPage}
            className={`px-4 py-2 rounded-md transition-colors ${
              currentPage === 1
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-black text-white hover:bg-gray-800"
            }`}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <div className="h-[40px] w-[40px] py-[8px] px-[16px] rounded-md text-center bg-[#eab43e] text-white">
            <span>{currentPage}</span>
          </div>
          <button
            onClick={handleNextPage}
            className={`px-4 py-2 rounded-md transition-colors ${
              currentPage === totalPages
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-black text-white hover:bg-gray-800"
            }`}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div> */}
      <div className="ag-theme-quartz mt-6" style={{ height: 500 }}>
        <AgGridReact
          modules={[ClientSideRowModelModule]}
          rowData={currentData}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[10, 25, 50]}
        />
      </div>
      <GlobalModalV1 isOpen={isOpen} onClose={onClose} width={"800px"}>
        <EmployeeForm
          onClose={onClose}
          isUpdateModal={true}
          selectedData={selectedData}
        />
      </GlobalModalV1>
    </div>
  );
};

export default EmployeeTable;
