import {
  MdBarChart,
  MdOutlineCreditCard,
  MdAutoFixHigh,
  MdGridView,
  MdHealthAndSafety,
  MdOutlineAirplanemodeActive,
} from "react-icons/md";

import { FaUserCircle, FaUserClock  } from "react-icons/fa";
import { RiCalendarScheduleFill } from "react-icons/ri";



export const menuItems = [
  {
    menuLabel: "dashboard",
    icon: MdGridView,
    link: "main",
    roles: ["admin"]
  },
  {
    menuLabel: "orders",
    icon: MdBarChart,
    link: "orders",
    roles: ["admin"]
  },
  {
    menuLabel: "customers",
    icon: MdOutlineCreditCard,
    link: "customers",
    roles: ["admin"]
  },

  {
    menuLabel: "menu",
    icon: MdAutoFixHigh,
    link: "menu",
    roles: ["admin"]
  },
  {
    menuLabel: "employees",
    icon: FaUserCircle,
    link: "employees",
    roles: ["admin"]
  },
  {
    menuLabel: "attendance overview",
    icon: FaUserCircle,
    link: "attendance-overview",
    roles: ["admin"]
  },
  {
    menuLabel: "attendance",
    icon: FaUserClock ,
    link: "attendance",
    roles: ["employee"]
  },
  {
    menuLabel: "schedule",
    icon: RiCalendarScheduleFill,
    link: "schedule",
    roles: ["admin", "employee"]
  },
  {
    menuLabel: "complaints",
    icon: MdHealthAndSafety,
    link: "complaints",
    roles: ["admin"]
  },
  {
    menuLabel: "sales report",
    icon: MdOutlineAirplanemodeActive,
    link: "sales-report",
    roles: ["admin"]
  },
];
