import React, { useEffect, useState } from "react";
import { useAllEmployees } from "../features/Employee/employee-service";
import { Modal, Button } from "antd";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import CustomToolbar from "./CalendarToolbar";
const OverviewCalendar = () => {
  const {
    data: allEmployees,
    isLoading,
    error,
  } = useAllEmployees("/api/employees");

  const localizer = momentLocalizer(moment);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [events, setEvents] = useState([]);

  //Transform Data
  useEffect(() => {
    if (allEmployees && Array.isArray(allEmployees.employee)) {
      const transformedEvents = allEmployees.employee.flatMap((employee) => {
        if (
          Array.isArray(employee.attendance) &&
          employee.attendance.length > 0
        ) {
          const approvedSchedules = employee.schedules?.filter(
            (schedule) => schedule.status === "approved"
          );
          return employee.attendance.map((attendance) => ({
            title: `${employee.firstName} ${employee.lastName}`,
            status: attendance.status,
            jobTitle: employee.jobTitle,
            date: new Date(attendance.date),
            notes: attendance.notes,
            // schedule:
            //   employee.schedules && employee.schedules.length > 0
            //     ? employee.schedules[employee.schedules.length - 1]
            //     : null,
            schedule:
              approvedSchedules && approvedSchedules.length > 0
                ? approvedSchedules[approvedSchedules.length - 1]
                : null,
          }));
        }
        return [];
      });

      setEvents(transformedEvents);
    }
  }, [allEmployees]);

  const handleSlotClick = (slotInfo) => {
    const dateKey = moment(slotInfo.start).format("YYYY-MM-DD");
    setSelectedDate(dateKey);
    const filteredData = events.filter((event) =>
      moment(event.date).isSame(slotInfo.start, "day")
    );

    console.log("Filtered Data===>", filteredData);
    setModalData(filteredData);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setModalData([]);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  const groupedEvents = events.reduce((acc, event) => {
    if (event.status === "Present") {
      const dateKey = moment(event.date).format("YYYY-MM-DD");
      if (!acc[dateKey]) acc[dateKey] = [];
      acc[dateKey].push(event.title);
    }
    return acc;
  }, {});

  //   const formattedEvents = Object.keys(groupedEvents).map((dateKey) => {
  //   const employeeNames = groupedEvents[dateKey];
  //   const displayNames = employeeNames.slice(0, 2);
  //   const additionalCount = employeeNames.length - 2;

  //   const displayText = additionalCount > 0
  //     ? `${displayNames.join('\n')}\n+${additionalCount} more`
  //     : displayNames.join('\n');

  //   return {
  //     date: dateKey,
  //     employees: displayText,
  //   };
  // });

  console.log("All employees===>", allEmployees);
  console.log("Modal Data==>", modalData);
  console.log("Transformed Events===>", events);

  // const test = Object.keys(groupedEvents).map((dateKey) => ({
  //   title: groupedEvents[dateKey].join("\n"),
  //   start: new Date(dateKey),
  //   end: new Date(dateKey),
  // }));
  // console.log("Grouped Events===>", test[0].title);

  return (
    <div>
      <Calendar
        mode="editable"
        localizer={localizer}
        events={Object.keys(groupedEvents).map((dateKey) => ({
          title: groupedEvents[dateKey].join("\n"),
          start: new Date(dateKey),
          end: new Date(dateKey),
        }))}
        selectable
        defaultView="month"
        views={["month", "week", "day"]}
        style={{ height: "80vh" }}
        onSelectSlot={handleSlotClick}
        eventPropGetter={(event) => ({
          style: {
            backgroundColor: "#34D399",
            color: "white",
            borderRadius: "0.25rem",
          },
        })}
        dayPropGetter={(date) => {
          const isToday = moment(date).isSame(moment(), "day");
          return {
            style: {
              backgroundColor: isToday ? "#EFF6FF" : "",
              border: isToday ? "2px solid #3B82F6" : "",
            },
          };
        }}
        components={{
          toolbar: CustomToolbar,
        }}
      />

      <Modal
        title={`Attendance Details for ${selectedDate}`}
        open={isModalVisible}
        onCancel={handleCloseModal}
        footer={false}
        width={"800px"}
      >
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto border-collapse">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2 border-b text-left text-sm font-semibold text-gray-700">
                  Employee
                </th>
                <th className="px-4 py-2 border-b text-left text-sm font-semibold text-gray-700">
                  Status
                </th>
                <th className="px-4 py-2 border-b text-left text-sm font-semibold text-gray-700">
                  Job Title
                </th>
                <th className="px-4 py-2 border-b text-left text-sm font-semibold text-gray-700">
                  Notes
                </th>
                <th className="px-4 py-2 border-b text-left text-sm font-semibold text-gray-700">
                  Shift 1 <br />
                  (9:00 AM - 5:00 PM)
                </th>
                <th className="px-4 py-2 border-b text-left text-sm font-semibold text-gray-700">
                  Shift 2 <br />
                  (5:00 PM - 2:00 AM)
                </th>
              </tr>
            </thead>
            <tbody>
              {modalData.map((data) => (
                <tr key={data._id} className="border-b hover:bg-gray-50">
                  <td className="px-4 py-2 text-sm text-gray-700">
                    {data.title}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-700">
                    {data.status}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-700">
                    {data.jobTitle}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-700">
                    {data.notes}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-700 capitalize">
                    {data?.schedule?.shift === "morning"
                      ? data?.schedule?.shift
                      : "-" || "N/A"}
                  </td>{" "}
                  <td className="px-4 py-2 text-sm text-gray-700 capitalize">
                    {data?.schedule?.shift === "night"
                      ? data?.schedule?.shift
                      : "-" || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default OverviewCalendar;
