import React, { useEffect, useState } from "react";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import toast from "react-hot-toast";
import {
  useGetAttendance,
  useMarkAttendance,
} from "../../features/Attendance/attendance-service";
import { useSelector } from "react-redux";
import { useFetchEmployeeById } from "../../features/Employee/employee-service";
import AttendanceCalendar from "./components/AttendanceCalendar";

const Attendance = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const { user } = useSelector((state) => state.auth);

  //Getting Employee Data
  const {
    data: employeeData,
    isLoading,
    error,
  } = useFetchEmployeeById(`/api/employee/${user?.EmployeeRef}`);

  //Getting Attendance Data
  const { data: getAttendanceData } = useGetAttendance(
    `/api/attendance/${user?.EmployeeRef}`
  );

  //Mutations
  const markAttendance = useMarkAttendance();


  console.log("User===>", user);

  useEffect(() => {
    if (getAttendanceData?.attendance) {
      const transformedData = getAttendanceData.attendance.map(
        (attendance) => ({
          id: attendance._id,
          title: attendance.status,
          start: new Date(attendance.date),
          end: new Date(attendance.date),
        })
      );
      setAttendanceData(transformedData);
    }
  }, [getAttendanceData]);

  if (isLoading) return <div>Loading...</div>;

  console.log("All attendance data==>", getAttendanceData?.attendance[0]);
  console.log("Employee Data test==>", employeeData);

  if (user?.role !== "employee") return null;

  // Handle slot selection (mark/unmark attendance)
  const handleSelectSlot = async (slotInfo) => {
    if (
      moment(slotInfo.start).isBefore(moment().startOf("day")) ||
      moment(slotInfo.start).isAfter(moment().startOf("day"))
    ) {
      toast.error("You cannot mark attendance for other dates!", {
        duration: 4000,
        position: "bottom-center",
      });
      return;
    }

    const selectedDate = moment(slotInfo.start).startOf("day").toISOString();

    const existingAttendance = attendanceData.find(
      (attendance) =>
        moment(attendance.start).startOf("day").toISOString() === selectedDate
    );
    console.log("Existing Attendance==>", existingAttendance?.id);

    if (existingAttendance) {
      toast.error(
        "Attendance already marked, you need to contact admin in order to change the status.",
        {
          duration: 6000,
          position: "bottom-center",
        }
      );
      console.log("Attendance already exists");
      // Unmark attendance (delete attendance via API)
      // deleteAttendance(existingAttendance.id, {
      //   onSuccess: () => {
      //     // Update state to remove the unmarked attendance
      //     setAttendanceData((prevData) =>
      //       prevData.filter(
      //         (attendance) => attendance.id !== existingAttendance.id
      //       )
      //     );
      //     toast.success("Attendance unmarked successfully!", {
      //       duration: 4000,
      //       position: "bottom-center",
      //     });
      //   },
      //   onError: (error) => {
      //     toast.error("Failed to unmark attendance!", {
      //       duration: 4000,
      //       position: "bottom-center",
      //     });
      //     console.error(error);
      //   },
      // });
    } else {
      const data = {
        date: selectedDate,
        status: "Present",
        notes: "Marked by employee",
      };

      try {
        const response = await markAttendance.mutateAsync({
          apiUrl: `/api/attendance/${user?.EmployeeRef}`,
          data,
        });

        const newAttendance = {
          id: response.employee.attendance.at(-1)._id,
          title: "Present",
          start: new Date(slotInfo.start),
          end: new Date(slotInfo.start),
        };

        setAttendanceData((prevData) => [...prevData, newAttendance]);

        toast.success("Attendance marked successfully!", {
          duration: 4000,
          position: "bottom-center",
        });
      } catch (error) {
        toast.error("Failed to mark attendance!", {
          duration: 4000,
          position: "bottom-center",
        });
        console.error(error);
      }
    }
  };

  

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      Header
      <h1 className="text-3xl font-bold text-gray-800 mb-6">
        Attendance Calendar
      </h1>
      {/* Legend */}
      <div className="flex items-center space-x-4 mb-6">
        <div className="flex items-center">
          <div className="w-4 h-4 bg-green-500 rounded-full mr-2"></div>
          <span className="text-gray-700">Present</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4 bg-red-500 rounded-full mr-2"></div>
          <span className="text-gray-700">Absent</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4 bg-gray-300 rounded-full mr-2"></div>
          <span className="text-gray-700">Unmarked</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4 border-2 border-blue-500 rounded-full mr-2"></div>
          <span className="text-gray-700">Today</span>
        </div>
      </div>
      {/* Calendar */}
      <div className="bg-white rounded-lg shadow-md p-4">
        <AttendanceCalendar
          events={attendanceData}
          mode="editable"
          handleSelectSlot={handleSelectSlot}
        />
      </div>
      
    </div>
  );
};

export default Attendance;
