import { Tooltip } from "antd";
import React from "react";
import { TbPlayerEject } from "react-icons/tb";

const RejectBtn = ({ onClick, ...props }) => {
  return (
    <Tooltip title="Reject Changes" color="#ffa500">
      <button
        className={`rounded-full bg-[#ffa500] p-1 text-white`}
        onClick={onClick}
        onMouseDown={(e) => e.stopPropagation()}
        {...props}
      >
        <TbPlayerEject />
      </button>
    </Tooltip>
  );
};

export default RejectBtn;
