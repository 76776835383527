import React, { useEffect, useState } from "react";
import { useAllOrderStats, useRecentOrders } from "../features/orders/orders-service";
import TableComponent from "../component/Table";
import ReviewBar from "../component/ReviewBar";
import EntriesDropdown from "../component/DropDown";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { LiaTimesSolid } from "react-icons/lia";
import axios from 'axios'

const DashboardHome = () => {
  const [selectedRows, setSelectedRows] = useState([]);
   const [tableData, setTableData] = useState([]);
  const { data: orderStats } = useAllOrderStats();
  const {data: recentOrders} = useRecentOrders();
  console.log(recentOrders)
  const [filterProduct, setFilterProduct] = useState([]);

  useEffect(() => {
    if (Array.isArray(recentOrders?.orders)) {
      const formattedData = recentOrders?.orders?.map((product, index) => ({
        product,
        id: index + 1,
      }));
      setFilterProduct(formattedData);
    }
  }, [recentOrders]);
  

  console.log(filterProduct, "filterProduct");
    const btnComponent = (props) => {
      
      return (
        <div className="flex gap-5 pt-2">
          <button
            className={`rounded-full bg-gray-700 p-1 text-white`}
      
          >
            <MdOutlineRemoveRedEye />
          </button>
  
          <button
            className="rounded-full bg-[#35f8fe] p-1 text-white"
 
          >
            <AiFillEdit />
          </button>
  
          <button
            className="rounded-full bg-[#EE5D50] p-1 text-white"
        
          >
            <LiaTimesSolid />
          </button>
        </div>
      );
    };

  const orderStatusData = [
    { title: "Total Orders", numbers: orderStats?.data?.totalOrders || 0 },
    { title: "Canceled Orders", numbers: orderStats?.data?.canceledOrders || 0 },
    { title: "Pending Orders", numbers: orderStats?.data?.pendingOrders || 0 },
    { title: "Successed Orders", numbers: orderStats?.data?.successedOrders || 0 },
    
  ];

  const columns = [
    {
      headerName: "ID",
      // field: "product.id",
      field: "id"
    },
    {
      headerName: "Customer",
      field: "product.ticketName", 
    },
    {
      headerName: "Amount",
      field: "product.totalMoney.amount", 
    },
    {
      headerName: "Total Items",
      valueGetter: (params) => params?.data?.product?.lineItems?.length,
    },
    // {
    //   headerName: "Phone",
    //   field: "product.?.pickupDetails?.recipient?.phoneNumber",
    // },
    {
      headerName: "Status",
      field: "product.state",
    },
    {
      headerName: "Action",
      cellRenderer: btnComponent,
      pinned: "right",
    },
  ];
  

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,
    floatingFilter: true,
  };

  const items = [
    {
      label: "1st menu item",
      key: "1",
    },
    {
      label: "2nd menu item",
      key: "2",
    },
    {
      label: "3rd menu item",
      key: "3",
      danger: true,
    },
    {
      label: "4th menu item",
      key: "4",
      danger: true,
      disabled: true,
    },
  ];

  return (
    <div className="mt-2">
      <div>
        <h1 className="text-[34px] font-bold border-b-4 border-primary">Dashboard</h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-8">
        <div>
          <h3 className="py-2 px-2 text-lg font-semibold border-b-2 border-primary w-[30%]">Order Status</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-6">
            {orderStatusData.map((card, index) => (
              <div
                key={index}
                className="bg-[#E5E5E2] rounded-3xl px-10 h-40 flex flex-col justify-center gap-6"
              >
                <p className="font-[400] capitalize">{card.title}</p>
                <p className="text-xl font-bold">{card.numbers}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-14">
          <ReviewBar />
        </div>
      </div>
      <div className="mt-14 ">
        <h1 className="border-b-2 border-b-primary w-[20%] text-[24px]">
          Recent Order Status
        </h1>
        {/* <TableComponent /> */}
        <div className="ag-theme-quartz mt-6" style={{ height: 500 }}>
                <AgGridReact
                  modules={[ClientSideRowModelModule]}
                  rowData={filterProduct}
                  columnDefs={columns}
                  defaultColDef={defaultColDef}
                  rowSelection="multiple"
                  suppressRowClickSelection={true}
                  pagination={true}
                  paginationPageSize={50}
                  paginationPageSizeSelector={[10, 25, 50]}
                />
            </div>
      </div>
    </div>
  );
};

export default DashboardHome;
