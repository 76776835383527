import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  clearCart,
  decreaseQuantity,
  increaseQuantity,
  removeFromCart,
} from "../features/cart/action";
import { FaRegClock, FaRegTrashAlt, FaTag, FaTrash } from "react-icons/fa";
import { TiWarningOutline } from "react-icons/ti";
import { MdArrowBackIos, MdOutlineLocationOn } from "react-icons/md";
import { IoPricetagsOutline } from "react-icons/io5";
import { Modal, Button, Radio } from "antd";
import axios from "axios";
import toast from "react-hot-toast";
import { dotSpinner } from "ldrs";
import { NextArrow, PrevArrow } from "../utils";
import Slider from "react-slick";

const tip = ["10%", "15%", "20%", "Other"];
const dates = [
  { day: "Today", date: "Dec 20" },
  { day: "Sat", date: "Dec 21" },
  { day: "Sun", date: "Dec 22" },
  { day: "Mon", date: "Dec 25" },
  { day: "Tue", date: "Dec 24" },
  { day: "Wed", date: "Dec 25" },
  { day: "Thu", date: "Dec 25" },
  { day: "Fri", date: "Dec 25" },
];

const times = [
  "7:00 AM",
  "7:15 AM",
  "7:30 AM",
  "7:45 AM",
  "8:00 AM",
  "8:15 AM",
  "8:30 AM",
  "8:45 AM",
  "9:00 AM",
];
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerPadding: "10px",
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
};

const reviewSettings = {
  ...settings,
  slidesPerView: 4,
  autoplay: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const apiUrl = process.env.REACT_APP_BASE_URL || "http://localhost:5000";

const Cart = () => {
  const cart = useSelector((state) => state.cart.cart);
  const dispatch = useDispatch();
  dotSpinner.register();

  const [selectedTip, setSelectedTip] = useState(null);
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("Sat Dec 21");
  const [selectedTime, setSelectedTime] = useState("7:00 AM");

  const navigate = useNavigate();

  const handleSelect = (value) => {
    setSelectedTip(value);
  };

  //increase items
  const handleIncrement = (id) => {
    dispatch(increaseQuantity(id));
  };

  //decrease items
  const handleDecrement = (id) => {
    dispatch(decreaseQuantity(id));
  };

  //delete items on cart
  const handleDeleteItem = (id) => {
    dispatch(removeFromCart(id));
  };

  //coupon input handler
  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  //coupon cancel
  const handleCancel = () => {
    setValue("");
    setFocused(false);
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleDateChange = (date) => setSelectedDate(date);
  const handleTimeChange = (e) => setSelectedTime(e.target.value);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let anotherApiResponse = null;
    const itemData = cart.map((item) => ({
      itemId: item.itemData.variations[0].id,
      quantity: item.quantity,
      specialInstructions: "Extra toasted",
    }));
    console.log("Catalog object id===>", itemData);
    try {
      const additionalData = {
        locationId: "2S2Z8AGQ60RYV",
        items: itemData,
        tipSettings: {
          allowCustomTip: true,
          suggestedTipPercentages: [15, 18, 20, 25],
        },
        fulfillment: {
          type: "PICKUP",
          pickupDetails: {
            pickupAt: "2024-03-20T15:00:00Z",
            note: "Please pickup at counter",
          },
        },
        shippingOptions: [
          {
            name: "Standard Delivery",
            amount: 5.99,
            duration: "2-3 business days",
          },
          {
            name: "Express Delivery",
            amount: 12.99,
            duration: "1 business day",
          },
        ],
        prePopulateShippingAddress: {
          city: "New York",
          state: "NY",
          postalCode: "10001",
          country: "US",
        },
        acceptedPaymentMethods: {
          applePay: true,
          googlePay: true,
          cashAppPay: true,
          card: true,
        },
      };

      anotherApiResponse = await axios.post(
        `${apiUrl}/api/advanced-checkout`,
        additionalData
      );
    } catch (error) {
      toast.error(`Something went wrong: ${error.message}`, {
        duration: 3000,
        position: "top-center",
      });
      console.error("API Error:", error.response?.data || error.message);
    } finally {
      // finally {
      //   if (anotherApiResponse?.data?.checkoutUrl) {
      //     // Add a listener to clear the cart after navigation
      //     const handlePageHide = () => {
      //       dispatch(clearCart());
      //       window.removeEventListener("pagehide", handlePageHide); // Clean up
      //     };

      //     // Listen for the `pagehide` event
      //     window.addEventListener("pagehide", handlePageHide);

      //     // Redirect to the checkout URL
      //     window.location.href = anotherApiResponse.data.checkoutUrl;
      //   } else {
      //     toast.error(`Unable to process order!`, {
      //       duration: 3000,
      //       position: "top-center",
      //     });
      //     console.error("Checkout URL not available.");
      //   }
      // }

      if (anotherApiResponse?.data?.checkoutUrl) {
        window.location.href = anotherApiResponse.data.checkoutUrl;
        dispatch(clearCart());
      } else {
        toast.error(`Unable to process order!`, {
          duration: 3000,
          position: "top-center",
        });
        console.error("Checkout URL not available.");
      }
    }
  };

  console.log("Cart data===>", cart);

  const renderSelections = (selections) => {
    return Object.entries(selections).map(([category, value]) => {
      // For section headers (categories)
      if (category.startsWith('>') || category.startsWith('~')) {
        return (
          <div key={category} className="mt-3">
            <div className="font-medium">{category}</div>
            <div className="text-gray-600 ml-2">{value}</div>
            <div className="text-gray-600 ml-2">{value}</div>
          </div>
        );
      }
      
      // For add-ons array
      if (Array.isArray(value)) {
        return (
          <div key={category} className="mt-3">
            <div className="font-medium">{category}</div>
            {value.map((addon) => (
              <div key={addon.id} className="text-gray-600 ml-2">
                {addon.name}
                {addon.price !== "0" && ` (+ US$${(Number(addon.price) / 100).toFixed(2)})`}
              </div>
            ))}
          </div>
        );
      }

      // For single selections like "Regular" or special categories
      return (
        <div key={category} className="mt-3">
          <div className="font-medium">{category}</div>
          <div className="text-gray-600 ml-2">
            {value}
          </div>
        </div>
      );
    });
  };

  return (
    <section className="cartMain py-8">
      <div className="container mx-auto px-4">
        <Link
          to="/menu"
          className="flex items-center mb-6 text-sm cursor-pointer hover:text-[#eab43e] duration-200"
        >
          <MdArrowBackIos />
          Continue shopping
        </Link>
        <div className="mb-6">
          <h2 className="text-2xl font-bold text-start">Your Cart</h2>
        </div>

        <div className="flex md:flex-row flex-col justify-between gap-20 w-full">
          <div className="w-full md:w-[50%]">
            <div className="border border-zinc-200 rounded-lg shadow-md p-4">
              <div className="border-b border-zinc-200 pb-2 mb-4">
                <h6 className="text-lg font-semibold">
                  Your Items <span>({cart.length} ITEMS)</span>
                </h6>
              </div>
              <ul className="space-y-4">
                {cart.map((item, index) => (
                  <li
                    key={index}
                    className="flex min-h-[200px] items-start justify-between border-zinc-200 border-b pb-4"
                  >
                    <div className="w-32 h-28">
                      <img
                        src={item.image}
                        alt={item.name}
                        className="w-full h-full object-cover rounded-lg"
                      />
                      <div className="w-full flex flex-1 py-3">
                        <p className="font-medium text-sm uppercase">
                          {item?.itemData?.name}{" "}
                          <span className="text-xs font-normal capitalize">
                            (
                            {
                              item?.itemData?.variations[0]?.itemVariationData
                                ?.name
                            }
                            )
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 px-8">
                      <h3 className="font-semibold text-lg">{item.name}</h3>
                      {item.selections && renderSelections(item.selections)}
                      <div className="mt-2">
                        <span className="text-sm text-gray-500">
                          Quantity: {item.quantity}
                        </span>
                      </div>
                      <div className="flex items-center mt-4 space-x-3">
                        <button
                          onClick={() => handleDecrement(item.id)}
                          className="w-7 h-7 bg-[#eab43e] rounded-full flex items-center justify-center text-white hover:bg-[#d9a636] transition-colors text-lg font-medium"
                        >
                          -
                        </button>
                        <span className="w-8 text-center text-gray-700 font-medium">
                          {item.quantity}
                        </span>
                        <button
                          onClick={() => handleIncrement(item.id)}
                          className="w-7 h-7 bg-[#eab43e] rounded-full flex items-center justify-center text-white hover:bg-[#d9a636] transition-colors text-lg font-medium"
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="text-right">
                      <p className="font-semibold text-lg">
                        $
                        {(
                          item?.itemData?.variations[0]?.itemVariationData
                            ?.priceMoney?.amount * item?.quantity
                        ).toFixed(2)}
                      </p>
                      <button
                        onClick={() => handleDeleteItem(item.id)}
                        className="text-yellow-500 mt-2"
                      >
                        <FaRegTrashAlt />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="mt-4">
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={() => navigate("/menu")}
                  className="w-full disabled:opacity-50 disabled:cursor-not-allowed block h-[60px] text-center bg-[#eab43e] text-white py-5 rounded hover:[#eab43e]"
                >
                  {cart.length > 0 ? (
                    <p className="font-normal">Add more items</p>
                  ) : (
                    <p className="font-normal">Add items</p>
                  )}
                </button>
              </div>
            </div>
          </div>
          <form
            onSubmit={onSubmit}
            className="md:w-[30%] h-[900px] w-full border border-zinc-200 rounded-lg shadow-md p-4 space-y-8"
          >
            <div className="space-y-6">
              <div className="flex justify-between">
                <p className="uppercase font-normal text-sm">How to get it</p>
                <button
                  type="button"
                  onClick={handleOpenModal}
                  className="text-blue-500 hover:text-blue-600"
                >
                  Edit
                </button>
              </div>
              <Modal
                visible={isModalOpen}
                onCancel={handleCloseModal}
                footer={
                  <button
                    className="bg-[#eab43e] hover:bg-yellow-600 transition-all duration-200 text-white p-4 rounded-md w-full font-medium"
                    onClick={handleCloseModal}
                  >
                    Change {selectedDate} ({selectedTime})
                  </button>
                }
              >
                <div className="mb-4">
                  <h3 className="font-medium text-2xl my-4 border-b border-zinc-200 pb-4">
                    Schedule Order
                  </h3>
                  <h3 className="font-medium text-lg">Select pickup time</h3>

                  <p className="text-gray-500 text-sm">
                    Choose from the available timeslots for your order
                  </p>
                </div>
                <div className="relative my-6">
                  <Slider {...reviewSettings} className="review-slider">
                    {dates.map((date) => (
                      <div
                        key={date.date}
                        className={`p-4 rounded-md cursor-pointer text-center ${
                          selectedDate === `${date.day} ${date.date}`
                            ? "bg-[#eab43e] text-white"
                            : "bg-zinc-100 hover:bg-gray-200"
                        }`}
                        onClick={() =>
                          handleDateChange(`${date.day} ${date.date}`)
                        }
                      >
                        <div className="font-semibold">{date.day}</div>
                        <div className="text-sm">{date.date}</div>
                      </div>
                    ))}
                  </Slider>
                </div>
                {/* <div className="flex space-x-2 mb-6 overflow-x-auto">
                  {dates.map((date) => (
                    <div
                      key={date.date}
                      className={`p-4 rounded-md cursor-pointer text-center ${
                        selectedDate === `${date.day} ${date.date}`
                          ? "bg-blue-500 text-white"
                          : "bg-gray-100 hover:bg-gray-200"
                      }`}
                      onClick={() =>
                        handleDateChange(`${date.day} ${date.date}`)
                      }
                    >
                      <div className="font-semibold">{date.day}</div>
                      <div className="text-sm">{date.date}</div>
                    </div>
                  ))}
                </div> */}
                <Radio.Group
                  value={selectedTime}
                  onChange={handleTimeChange}
                  className="flex flex-col space-y-2 overflow-y-scroll h-90"
                >
                  {times.map((time) => (
                    <div className="border-b border-zinc-200 pb-4 last:border-none">
                      <Radio key={time} value={time}>
                        <p className="text-base font-normal">{time}</p>
                      </Radio>
                    </div>
                  ))}
                </Radio.Group>
              </Modal>
              <div className="bg-[#F9EECF] flex gap-3 justify-center items-start p-4 rounded-md">
                <TiWarningOutline size={30} />
                <p className="text-sm font-normal">
                  Please note, your order will be ready on our next business
                  day: {selectedDate}.
                </p>
              </div>
              <div className="border-b border-zinc-200 pb-6">
                <div className="flex gap-3 items-center p-2 rounded-md">
                  <MdOutlineLocationOn size={22} />
                  <p className="text-sm font-normal">
                    Pickup: 1810 Peachtree Pkwy Suite 108
                  </p>
                </div>
                <div className="flex gap-3 items-center p-2 rounded-md">
                  <FaRegClock />
                  <p className="text-sm font-normal">{selectedDate}, {selectedTime}</p>
                </div>
              </div>
              <div className="space-y-6 border-b border-zinc-200 pb-6">
                <p className="uppercase font-normal text-sm">Add a tip</p>

                <div className="flex flex-1 justify-between w-full">
                  {tip.map((value) => (
                    <div
                      key={value}
                      onClick={() => handleSelect(value)}
                      className={`w-20 h-16 p-4 flex flex-col justify-center items-center rounded-md text-sm font-normal duration-200 cursor-pointer 
            ${
              selectedTip === value
                ? "bg-[#eab43e] text-white"
                : "bg-[#F1F1F1] hover:bg-[#ebebeb]"
            }`}
                    >
                      {value}
                    </div>
                  ))}
                </div>
              </div>
              <div className="border-b border-zinc-200 pb-6">
                <div className="flex items-center border border-zinc-200 rounded-lg p-2">
                  <div className="flex flex-1 items-center gap-3">
                    <IoPricetagsOutline size={22} />
                    <input
                      type="text"
                      placeholder="Add coupon or gift card"
                      value={value}
                      onFocus={() => setFocused(true)}
                      onBlur={() => {
                        if (!value) setFocused(false);
                      }}
                      onChange={handleInputChange}
                      className="flex-1 outline-none p-2"
                    />
                  </div>
                  {focused && (
                    <button
                      type="button"
                      onClick={
                        value ? () => alert("Coupon applied!") : handleCancel
                      }
                      className={`text-white px-4 py-2 rounded-lg bg-[#eab43e] ${
                        value ? " hover:bg-[#c59734]" : "bg-gray-300"
                      } transition-all ml-2`}
                    >
                      {value ? "Apply" : "Cancel"}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className=" ">
              <div className="border-b border-zinc-200 pb-2 mb-4">
                <h6 className="text-lg font-semibold">Cart Summary</h6>
              </div>
              <ul className="space-y-4 text-s">
                <li className="flex justify-between">
                  Subtotal{" "}
                  <span>
                    $
                    {cart
                      .reduce(
                        (total, item) =>
                          total +
                          item.itemData?.variations[0]?.itemVariationData
                            ?.priceMoney?.amount *
                            item.quantity,
                        0
                      )
                      .toFixed(2)}
                  </span>
                </li>
                <li className="flex justify-between">
                  Estimated taxes (GA, US) <span>$5</span>
                </li>
                {selectedTip && (
                  <li className="flex justify-between">
                    Tip ({selectedTip}) <span>$5</span>
                  </li>
                )}
                <li className="flex justify-between">
                  Estimated order total
                  <span>
                    $
                    {(
                      cart.reduce(
                        (total, item) =>
                          total +
                          item.itemData?.variations[0]?.itemVariationData
                            ?.priceMoney?.amount *
                            item.quantity,
                        0
                      ) + 5
                    ).toFixed(2)}
                  </span>
                </li>
                <li>
                  <small className="text-gray-500">
                    Additional taxes and fees will be calculated at checkout
                  </small>
                </li>
              </ul>
              {cart.length > 0 && (
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full mt-4 disabled:opacity-50 disabled:cursor-not-allowed bg-[#eab43e] text-white py-4 rounded-lg font-medium hover:bg-[#d9a636] transition-colors text-lg flex justify-center items-center gap-4"
                >
                  Proceed to Payment
                  {isLoading && (
                    <l-dot-spinner size="20" speed="0.8" color="white" />
                  )}
                </button>
              )}
              {cart.length === 0 && isLoading && (
                <p className="font-normal text-center my-6 text-[#eab43e]">
                  Your are being redirected to payment page shortly...
                </p>
              )}
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Cart;
