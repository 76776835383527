import React, { useEffect, useState } from "react";
import {
  useEmployeeById,
  useFetchEmployeeById,
} from "../../../features/Employee/employee-service";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useGetAttendance } from "../../../features/Attendance/attendance-service";
import AttendanceCalendar from "../../Attendance/components/AttendanceCalendar";

const EmployeeDetail = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const { id } = useParams();

  const {
    data: employee,
    isLoading,
    error,
  } = useFetchEmployeeById(`/api/employee/${id}`);

  const { data: getAttendanceData } = useGetAttendance(
    `/api/attendance/${id}`
  );
        


  useEffect(() => {
    if (getAttendanceData?.attendance) {
      const transformedData = getAttendanceData.attendance.map(
        (attendance) => ({
          id: attendance._id,
          title: attendance.status,
          start: new Date(attendance.date),
          end: new Date(attendance.date),
        })
      );
      setAttendanceData(transformedData);
    }
  }, [getAttendanceData]);

  if (isLoading) return <div>Loading...</div>;

  const { firstName, lastName, phoneNo, jobTitle } = employee?.employee;


  console.log("Attendance Data===>", getAttendanceData);

  console.log("employee", employee);
  
  return (
    <div>
      <div className="flex flex-wrap justify-between items-center my-10">
        <h1 className="text-2xl font-medium">
          {firstName} {lastName}
        </h1>
        <p className="text-xl font-medium">{phoneNo}</p>
        <p className="text-xl font-medium">{jobTitle}</p>
      </div>
      <div>
        <AttendanceCalendar events={attendanceData} mode="readOnly" />
      </div>
    </div>
  );
};

export default EmployeeDetail;
